@import '_variables';

.edit-donation-options {
  &-required-message {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $light-gray;
  }

  &-subheader-label {
    margin-bottom: 1rem;
    display: flex;
    gap: 0.25rem;
    align-items: flex-end;
  }

  &-optional-message.MuiTypography-root {
    color: $gray;
  }

  &-options-container,
  &-recurring-options {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $light-gray;

    &-block {
      margin-top: 1.5rem;
      margin-left: -2rem;
    }
  }

  &-custom-option {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }

  &-new-amount-input,
  &-new-recurring-amount-input {
    display: none;

    &-shown {
      display: block;
    }
  }

  &-add-option,
  &-add-recurring-option {
    cursor: pointer;
    color: $blue;
    display: flex;
    gap: 0.25rem;
    align-items: center;

    .MuiTypography-root {
      color: $blue;
    }

    &:hover {
      color: $dark-blue;
      .MuiTypography-root {
        color: $dark-blue;
      }
    }
  }
}

.EditGiftOption {
  // Must override MUI accordion styles
  margin-bottom: 1.5rem !important;
  box-shadow: none !important;

  &:last-of-type {
    margin-bottom: 0 !important;
  }

  div {
    width: 100%;
  }

  &::before {
    content: none !important;
  }

  .EditGiftOption-summary {
    transition-duration: 250ms;
    width: 100%;
    border-radius: 0.25rem;
    padding: 1rem !important;
    background-color: rgba($blue, 0.05) !important;
    cursor: default !important;

    &.expanded {
      background-color: white !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid $blue;
      border-left: 1px solid $blue;
      border-right: 1px solid $blue;
    }

    .MuiAccordionSummary-contentGutters {
      margin: 0 !important;
    }

    .MuiTypography-h3 {
      margin-right: 0.5rem;
    }

    .MuiCheckbox-root {
      margin-left: 0;
    }
  }

  .EditGiftOption-details {
    transition-duration: 250ms;
    background-color: rgba($blue, 0.05) !important;

    &.expanded {
      background-color: white !important;
      border-left: 1px solid $blue;
      border-right: 1px solid $blue;
      border-bottom: 1px solid $blue;

      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  &-tag-label-container {
    margin-top: 1rem !important; // used !important to override MUI Stack margins
  }

  &-tag-label-optional-indicator.MuiTypography-root {
    color: $gray;
  }
}

.EditGiftOption-amountText {
  color: $dark-gray !important;
}

@import '_variables';

.dashboard-jumbo-container {
  .dashboard-total-container {
    min-width: 19rem;
    border: 1px solid $light-gray;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-right: 1rem;

    &.empty {
      .value {
        color: $dark-gray;
      }
    }

    .value {
      color: $blue;
    }

    .total-wrapper {
      margin-top: 0.75rem;

      .comparison-wrapper {
        margin-left: 0.75rem;
        font-size: 0.75rem;

        .comparison-percent-wrapper {
          &.increase {
            color: $green;

            .comparison-percent {
              color: $green;
            }
          }

          &.decrease {
            color: $red;

            .comparison-percent {
              color: $red;
            }
          }
        }

        .comparison-unit {
          margin-left: 0.375rem;
        }
      }
    }
  }

  .icon-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    background-color: $very-light-blue;
    border-radius: 0.5rem;
    margin-right: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@import '_variables';
@import '_mixins';

.designations {
  &-breadcrumbs {
    width: 100%;
  }

  &-header {
    align-items: center;
    background-color: white;
    border-bottom: solid 1px $light-gray;
    display: flex;
    flex-direction: column;
    height: 12rem;
    padding: 1.5rem 2.5rem;
    width: 100%;

    h1,
    h2 {
      margin-top: 1.5rem;
    }
  }

  &-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding-bottom: 3rem;

    &-row {
      align-items: stretch;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      width: 45rem;
    }

    &-label {
      align-items: stretch;
      display: flex;
      justify-content: flex-end;
      padding-right: 1.5rem;
      width: 45%;

      .MuiSvgIcon-root {
        margin-right: 0.25rem;
        height: 1.25rem;
      }

      &.center-label {
        align-items: center;
      }

      &.top-label {
        padding-top: 0.5rem;
      }
    }

    &-field {
      width: 55%;
    }
  }

  &-date-pickers {
    align-items: center;
    display: flex;

    h3 {
      padding: 0 0.5rem;
    }
  }

  &-groups {
    background-color: white;
    border: 1px solid $light-gray;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    height: 9rem;
    overflow: scroll;
    padding: 0.625rem 1rem;

    .MuiTypography-root {
      font-family: $display-font;
    }

    .MuiFormControlLabel-root:hover {
      background-color: $lighter-gray;
      border-radius: 0.5rem;
    }

    .MuiSkeleton-root {
      height: 2rem;
    }

    &-label {
      align-items: center;
      display: flex;

      .MuiSvgIcon-root {
        font-size: 1rem;
        margin-left: 0.5rem;

        &:hover {
          stroke-width: 3;
        }
      }
    }

    &-new {
      align-items: center;
      color: $blue;
      cursor: pointer;
      display: flex;
      padding-top: 0.75rem;

      .MuiTypography-root {
        color: $blue;
      }

      &:hover {
        color: $dark-blue;
        .MuiTypography-root {
          color: $dark-blue;
        }
      }
    }
  }

  &-image-upload-container {
    height: 5rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    border: dashed $blue;
    background: white;

    h5 {
      font-size: 1.2rem;
    }

    span {
      font-size: 1.2rem;
      margin: 0 1rem;
    }

    .MuiButton-root {
      padding: 0.5rem 0.75rem;
      height: 2rem;
      font-size: 1.2rem;
    }
  }

  &-image-blocker {
    .blocker-indicator {
      margin-top: -2.5rem;
      padding-top: 0%;

      img {
        height: 10rem;
        width: auto;
      }
    }
  }

  &-submit-button-container {
    margin-top: 2rem;
    width: 30rem;

    display: flex;
    column-gap: 1rem;
  }
}

.share-template-modal .MuiPaper-root {
  width: 700px;

  .share-template-modal-description {
    margin-bottom: 1rem;
  }

  .share-input-options {
    display: flex;
    overflow: hidden;
    padding: 0.25rem;

    .MuiTypography-body {
      padding: 0.5rem;
      border-radius: 0.25rem;
      border: 1px solid gray;
    }
  }
}

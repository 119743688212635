#root {
  box-shadow: 0 0.25rem 2rem 0 #0000001a;
}

.app-container {
  min-height: 100vh;
  min-width: 61rem;
  background: #f4f7ff;
}
.fluid-container {
  margin-right: auto !important; /* !important overrides MUI styling */
  margin-left: auto !important; /* !important overrides MUI styling */
  max-width: 1500px;
}

@media (max-width: 1580px) {
  .fluid-container {
    margin-right: 40px !important; /* !important overrides MUI styling */
    margin-left: 40px !important; /* !important overrides MUI styling */
  }
}

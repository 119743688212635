@import '_variables';

.revenue-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.75rem;

  .channel-row {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    .row-label {
      display: flex;
      min-width: 40%;
    }

    .row-values {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .channel-color {
      width: 0.9rem;
      height: 0.9rem;
      margin: 0 0.9rem 0 0.5rem;
      border-radius: 100%;
      background-color: $blue;

      &.events {
        background-color: $purple;
      }

      &.peer-to-peer {
        background-color: $orange;
      }

      &.text-to-give {
        background-color: $green;
      }

      &.other {
        background-color: $pink;
      }
    }

    .channel-type {
      font-weight: 700;
      line-height: 0.8;
    }

    .total-amount {
      color: $green;
      font-weight: 700;
    }

    .revenue-percentage {
      color: $blue;
      font-size: 0.875rem;
      font-weight: 600;
    }
  }

  &.is-error {
    .total-amount,
    .revenue-percentage {
      color: $dark-gray;
    }
  }
}

@import '_variables';

.strategy {
  &-header {
    background: $navy;
    width: 100%;
    height: 11.125rem;
  }

  &-card {
    margin: -6.75rem 2.5rem 0;
    padding: 2.5rem 5rem 3.5rem;
    background-color: #fff;
    box-shadow: 0 4px 2.25rem rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }
}
.skeleton.MuiSkeleton-root {
  transform: none;

  &.skeleton-header {
    margin-bottom: 2rem;
  }
  &.skeleton-body {
    margin-bottom: 3rem;
  }
  &.skeleton-bottom {
    margin-top: 2.5rem;
  }
}

.skeleton-container {
  display: flex;
  justify-content: space-between;
}

@import '_variables';
@import '_mixins';

.ab-test-notes {
  @include custom-scrollbar;
  padding-right: 0.25rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 0;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);

  &-bottom-gradient::after {
    content: '';
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    text-align: center;
    width: 90%;
    height: 20%;
    background-image: linear-gradient(transparent, #fff);
  }

  &-loading {
    overflow: hidden;
    padding-right: 1.5rem;
  }

  .button-and-header-container {
    width: 100%;
  }

  .MuiFormControl-root {
    height: initial;
  }

  .MuiButton-root {
    margin-top: 1rem;
  }

  .MuiDivider-root {
    margin: 1rem 0;
    width: 100%;
    border-color: $light-gray;

    .MuiTypography-root {
      color: $dark-gray;
    }
  }

  .previous-activity-container {
    width: 100%;
    position: relative;

    .note-container,
    .skeleton-note-container {
      border: 1px solid $light-gray;
      padding: 1rem;
      border-radius: 4px;
      margin-bottom: 0.5rem;
      position: relative;

      &:last-child:not(.skeleton-note-container) {
        margin-bottom: calc(0.5rem + 10%);
      }

      &-user-note {
        color: $dark-gray;
      }

      .MuiSkeleton-root {
        &:first-child {
          width: 3rem;
        }

        &:last-child {
          width: 12rem;
        }
      }

      .note-publish-info {
        display: flex;
        justify-content: space-between;
        &-date {
          color: $gray;
        }
      }

      .skeleton-note-publish-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .MuiSkeleton-root {
          &:first-child {
            width: 4rem;
          }

          &:last-child {
            width: 6rem;
          }
        }

        &-date {
          color: $medium-gray;
        }
      }
    }

    .no-previous-notes-container {
      margin-top: 1rem;
      text-align: center;

      .MuiTypography-root {
        color: $dark-gray;
        opacity: 0.5;
      }

      > img {
        margin-top: 2.5rem;
      }
    }
  }
}

@import '_variables';

.template-card-box {
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  min-height: 11rem;
  max-width: 31%;
  border-radius: 8px;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  flex: 1 0 50%;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;

  .template-preview-header {
    height: 6.75rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    overflow: hidden;

    &.purple-gradient {
      background: $purple-gradient;
    }
    &.green-gradient {
      background: $green-gradient;
    }
    &.yellow-gradient {
      background: $yellow-gradient;
    }

    .preview-image {
      width: 11rem;
      height: 5.125rem;
      object-fit: cover;
      object-position: 0 0;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.08));
      z-index: 2;

      &-missing {
        display: none;
      }
    }
  }

  .template-preview-content {
    margin-top: 0;
    border-radius: 0px 0px 8px 8px;
  }

  .chip-wrapper {
    padding: 0.375rem 0.875rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 3;
    display: flex;
    flex-direction: column;
  }

  .shared-template-chip,
  .unpublished-changes-chip {
    background-color: $light-gray;
    color: $almost-black;
    margin-bottom: 0.5rem;
  }

  .template-description-text {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; // allows for 3 lines before overflowing and applying ellipsis
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .overview-hover {
    background: rgba(2, 0, 38, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
    align-items: center;
    visibility: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border-radius: 0.5rem;

    & button {
      width: 55%;
      margin-bottom: 1rem;

      &.test-form-button .MuiButton-startIcon svg {
        fill: $blue;
      }
    }
  }

  &:hover {
    .overview-hover {
      visibility: visible;
    }
  }

  .delete-btn {
    color: $red;
  }
}

@import '_variables';
@import '_mixins';

.giving-form-css-editor {
  .submit-custom-css {
    margin-top: 1.5rem;
    width: fit-content;
  }

  .cssTextareaContainer {
    .editing,
    .highlighting {
      position: absolute;
      top: 6.5rem;
      left: 0;
      padding: 10px;
      margin: 2.5rem 1.5rem;
      border: 1px solid $light-gray;
      border-radius: 0.5rem;
      background-color: white;
      width: calc(100% - 3rem);
      overflow: auto;
      white-space: nowrap;
      @include custom-scrollbar;
    }

    .editing,
    .highlighting,
    .highlighting * {
      font-size: 16px;
      font-family: monospace;
      line-height: 22px;
      tab-size: 2;
    }

    .editing {
      z-index: 1;
      color: transparent;
      background: transparent;
      caret-color: black;
      resize: none;
    }

    .highlighting {
      z-index: 0;
    }
  }
}

@import '_variables';

@mixin grid-item-label($col-start, $row-start) {
  grid-column: $col-start / span 1;
  grid-row: $row-start / span 1;
  text-align: right;
  margin-top: 1rem;
}

@mixin grid-item-textfield($col-start, $row-start) {
  grid-column: $col-start / span 2;
  grid-row: $row-start / span 1;
  .MuiOutlinedInput-root {
    height: 100%;
  }
}

.ab-test-feature-header {
  .button-container {
    width: 13.25rem;
    display: flex;
    flex-direction: row-reverse;
    .MuiButton-primary {
      order: -1;
    }
  }
}

.create-ab-test {
  margin-top: 10rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 18rem 5.5rem 7rem 3rem;
  row-gap: 1.5rem;
  column-gap: 0.75rem;
  padding: 0rem 16rem;

  .ab-test-image {
    grid-column: 2 / span 2;
  }

  .ab-test-description-label {
    @include grid-item-label(1, 2);
  }
  .ab-test-description-textfield {
    @include grid-item-textfield(2, 2);
  }

  .ab-test-assumption-label {
    @include grid-item-label(1, 3);
  }
  .ab-test-assumption-textfield {
    @include grid-item-textfield(2, 3);
  }

  .ab-test-notification-label {
    @include grid-item-label(1, 4);
  }
  .ab-test-notification-container {
    @include grid-item-textfield(2, 4);
    display: flex;
    align-items: center;
    gap: 1.5rem;
    justify-content: space-between;
  }
}

.ab-stepper-container {
  .ab-stepper {
    margin-top: 2rem;
    width: 30rem;
  }
}

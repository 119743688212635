@import '_variables';

.publish-ab-test-dialog {
  .MuiDialogContent-root {
    padding: 0 6rem;

    .dialog-content-text {
      color: black;
      display: block;
      font-family: $display-font;
      font-weight: 400;
      line-height: 1.5rem;
      margin-top: 1rem;
      text-align: left;

      &.publish-dialog-loading {
        text-align: center;
      }

      &.publish-dialog-loading:nth-of-type(2) {
        font-size: 0.875rem;
        margin: 0.25rem 0 5rem;
      }
    }

    .flask-gif {
      margin: 3rem 0 -3rem;
      width: 16rem;
    }
  }
}

.active-menu-container {
  margin-left: auto;
  margin-top: 2rem;
  width: 25.3125rem;
  max-width: 25.3125rem;
  min-width: 25.3125rem;
  margin-left: 1.875rem;
}

.active-menu-container > * {
  position: sticky;
  top: 18.25rem;
  margin-bottom: 2rem;
}

@import '_variables';

.edit-recurring-gift-equation {
  margin-bottom: 1.5rem;

  &.expanded {
    border: 1px solid $blue;
    border-radius: 0.25rem;
  }

  &.MuiPaper-root {
    box-shadow: none;

    &::before {
      content: none;
    }
  }

  .edit-recurring-gift-equation-summary {
    .MuiAccordionSummary-content {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0;

      &.Mui-expanded {
        justify-content: flex-end;
        padding: 0.5rem 1rem 0 0;
      }

      .edit-recurring-gift-equation-icon-container {
        align-self: flex-end;
        display: flex;
        justify-content: flex-end;
      }
    }

    &.MuiAccordionSummary-root {
      background-color: rgba($blue, 0.05);
      border-radius: 0.25rem;
      cursor: default !important; // forcing Mui to take this style
      padding: 1rem 1.5rem;
      transition-duration: 250ms;

      &.expanded {
        background-color: #fff;
        display: flex;
        justify-content: flex-end;
        min-height: auto; // Mui sets a min-height of 64px on the expanded summary, which we don't want
        padding: 0;
      }
    }
  }

  .edit-recurring-gift-equation-details {
    display: flex;
    flex-direction: column;
    padding-top: 0;

    .equation-details-header-container {
      margin-bottom: 1rem;

      h5 {
        margin-bottom: 0.5rem;
      }
    }

    .min-max-container,
    .percentage-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }

    .min-max-container > div {
      width: 48%;

      h6 {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@import '_variables';
@import '_mixins';

.table-component {
  .MuiTable-root {
    font-family: $display-font;

    .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
      font-weight: 700;
    }
  }

  .table-pagination {
    font-family: $display-font;

    input {
      font-family: $display-font;
    }
  }

  &-default-cell {
    max-width: 175px;
    word-wrap: break-word;
  }
}

// This renders in a popover outside of the table in the DOM
.table-pagination-select {
  font-family: $display-font;
}

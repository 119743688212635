@import '_variables';

.hosted-page-jumbotron-container {
  display: flex;
  justify-content: center;
  margin-top: 14rem;

  .enable-feature-jumbo {
    width: fit-content;
    padding-top: 1.5rem;
    background: rgba(2, 0, 38, 0.3);
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3.25rem;

    .hosted-page-text-container {
      max-width: 38.625rem;

      & h1 {
        color: #ffffff;
        font-size: 3rem;
        margin-bottom: 1rem;
        line-height: 125%;
      }

      & h5 {
        color: #ffffff;
        margin-bottom: 2rem;
        line-height: 125%;
      }
    }

    .hosted-page-example-container {
      margin-right: 3.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;

      img {
        width: 20rem;
        height: 20rem;
      }
    }
  }
}

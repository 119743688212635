@import '_variables';

.LookAndFeelMenu {
  h3 {
    margin-bottom: 1rem;
  }
  .gf-element-library-page-break {
    color: $blue;
    cursor: pointer;
  }
  .gf-element-library-divider {
    background-color: $light-gray;
    border: none;
    height: 1px;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border: unset;
  }
  .ColorPickerRow {
    margin-bottom: 0.75rem;
  }
}

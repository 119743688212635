@import '_variables';

.discard-ab-test-draft {
  .MuiPaper-root {
    max-width: 40rem;
    height: 27rem;
  }

  &-title span {
    color: white;
  }

  .MuiDialogContent-root {
    padding: 2.5rem 4.75rem;
  }

  .MuiDialogActions-root {
    padding: 1.5rem 4.75rem;
  }
}

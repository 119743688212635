@import '_variables';
@import '_mixins';

.aside-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 33%;
  width: 22.44rem;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  height: 32.0625rem;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);

  .caption-span {
    font-weight: 600;
    color: $almost-black;
  }
  .caption-title.MuiTypography-root {
    margin-bottom: 1rem;
  }
  .caption-campaign.MuiTypography-root {
    color: $gray;
    &:last-of-type {
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
    }
  }
  .label {
    margin-bottom: 1rem;

    span {
      font-weight: 400;
      font-family: 'Roboto';
      font-size: 1rem;
      line-height: 1.75rem;

      &.or {
        font-size: 0.875rem;
        line-height: 150%;
      }
    }
  }
}

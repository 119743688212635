@import '_mixins';

.new-designations-group-modal {
  &-title {
    &.MuiDialogTitle-root {
      .MuiTypography-root {
        color: white;
      }
    }
  }

  &-input-container {
    padding: 0.5rem 0 1rem;
    max-width: 36rem;
  }

  &-confirm {
    width: 10rem;
  }
}

.delete-designations-group-modal {
  .MuiDialogContent-root {
    max-width: 42rem;
  }

  &-confirm {
    width: 14rem;
  }

  &-title {
    &.MuiDialogTitle-root {
      .MuiTypography-root {
        color: white;
      }
    }
  }

  &-content {
    font-family: $display-font;

    li {
      margin: 0.5rem 0;
    }
  }
}

.confirm-designation-overwrite-modal {
  .MuiDialogContent-root {
    max-width: 42rem;
  }

  &-title {
    &.MuiDialogTitle-root {
      .MuiTypography-root {
        color: white;
      }
    }
  }
}

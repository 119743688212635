@import '_variables';

.publish-first-time-dialog {
  &__embed-code {
    position: relative;
    cursor: pointer;
    border: solid 1px $light-gray;
    padding: 0.25rem 2.5rem 0.25rem 1rem;
    border-radius: 0.5rem;
  }

  &__copy-icon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    stroke: $blue;
  }
}

.new-giving-form-dialog {
  .MuiDialog-paper {
    min-width: 38rem;
  }

  .MuiDialogTitle-root {
    background-color: #ffffff;
    padding: 1.5rem 2rem 0rem;

    button {
      background-color: #ffffff;
      right: 8px;
      top: 5px;

      svg {
        stroke: #020026;
        opacity: 0.5;
      }
    }
  }

  .MuiDialogContent-root {
    max-width: 32.125rem;
    align-self: center;
  }

  .DialogContentTitle {
    text-align: center;
  }

  .MuiTextField-root {
    padding: 1.25rem 0;
    width: 100%;
  }

  div.MuiDialogActions-root {
    background: #ffffff;
    justify-content: center;
    padding: 2.5rem;
  }
}

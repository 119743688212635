.element-library-email-opt-in {
  .element-library-email-opt-in-input-wrapper {
    .email-opt-in-prompt-header {
      margin-top: 1.125rem;
    }
    .email-opt-in-message-header {
      margin-top: 1.5rem;
    }
  }
}

@import '_variables';

.new-campaign-header {
  display: flex;
  width: 100%;
  height: 12rem;
  background-color: white;
  padding: 1.5rem 2.5rem;
  border-bottom: solid 1px $light-gray;
}

.new-campaign-header-stack {
  width: 100%;
}

.new-campaign-breadcrumbs {
  margin-right: auto;
}

.new-campaign-form {
  width: 36rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
}

.new-campaign-fields {
  width: 23rem;
}

.new-campaign-field-labels {
  margin-top: 1rem;
}

.new-campaign-button-container {
  width: 23rem;
  margin-top: 15rem;
}

.new-campaign-date-divider {
  padding-top: 0.785rem;
}

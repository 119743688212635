@import '_variables';

.end-ab-test-dialog {
  .MuiDialogContent-root {
    background-image: url('../../../assets/images/ellipse.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top 1.5rem center;
    padding-top: 2rem;
  }

  .MuiDialog-paper {
    width: 56.5rem;

    .MuiDialogTitle-root {
      .MuiTypography-h2 {
        color: #ffffff;
      }
    }

    .DialogContentTitle {
      margin-bottom: 0;
    }

    .variant-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 1.5rem;

      .end-ab-test-card {
        width: 22.25rem;

        .channel-preview-header {
          padding-top: 0;
        }

        &.selected {
          border: 2px solid $blue;

          .channel-preview-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
          }
        }

        &:not(.selected) {
          border: 2px solid transparent;
        }
      }

      .form-preview-skeleton {
        margin-top: 0.25rem;
        margin-bottom: 1.5rem;
        width: 22.25rem;
        height: 24.75rem;
        transform: none;
      }
    }
  }
}

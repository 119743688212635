.donor-line-graph-container {
  height: 100%;
  position: relative;
  width: 50%;

  .donor-line-graph {
    // using !important to override inline styles
    height: 100% !important;
    width: 100% !important;
  }
}

.select-gf-ab-test {
  padding-top: 10rem;

  .empty-state-container {
    display: flex;
    flex-direction: column;

    .view-campaigns-button {
      align-self: center;
      margin-top: 2rem;
    }
  }

  .section-header {
    padding: 2.5rem 0 1.5rem;
  }

  .giving-form-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    > * {
      box-sizing: content-box;
      width: 31%;
    }
  }
}

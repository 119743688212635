@import '_variables';

.MuiPaper-root.MuiAccordion-root.Mui-expanded.OptionalSection {
  margin: 0;
}

.OptionalSection {
  box-shadow: none !important;
  margin-top: 0 !important;

  &::before {
    content: none !important;
  }

  .OptionalSection-summary {
    width: 100%;
    min-height: 0 !important;
    padding: 0 !important;
    cursor: default !important;

    .OptionalSection-icon {
      width: 1.25rem;
      height: 1.25rem;

      .MuiSvgIcon-root {
        transform: scale(0.5);
        stroke-width: 2.5;
      }
    }

    .MuiAccordionSummary-contentGutters {
      margin: 0 !important;
    }

    .OptionalSection-title {
      color: $dark-gray;

      &--selected {
        color: $black;
      }
    }
  }

  .OptionalSection-details {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 2rem !important;
  }
}

@import '_variables';

.save-as-template-dialog {
  .MuiPaper-root {
    max-width: 45rem;
  }

  .MuiDialogTitle-root {
    background-color: inherit;
    button {
      background-color: white;
      color: $gray;
      border: 0;
      &:hover {
        background-color: $lighter-gray;
      }
    }
  }

  .MuiDialogContent-root {
    padding-top: 0.5rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .DialogContentTitle {
    text-align: center;
  }

  .MuiDialogContentText-root {
    margin-bottom: 3rem;
    text-align: center;
  }

  .MuiTextField-root {
    display: block;

    &:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }

    .MuiInputBase-root {
      width: 100%;
    }
  }

  .MuiDialogActions-root {
    background-color: white;
    padding-top: 0;
    padding-bottom: 3.5rem;
    padding-left: 6rem;
    padding-right: 6rem;
    justify-content: center;
    gap: 1rem;

    > :not(:first-of-type) {
      margin-left: 0;
    }

    .MuiButton-root {
      flex: 1;
    }
  }
}

@import '_variables';

#ElementLibrary-EditCustomFields {
  .EditCustomFields {
    // Must override MUI accordion styles
    margin-bottom: 1.5rem !important;
    box-shadow: none !important;

    div {
      width: 100%;
    }

    .EditCustomFields-fieldName {
      margin-left: 0.5rem;
    }

    .EditCustomFields-actionButtons {
      width: auto;
    }

    &::before {
      content: none !important;
    }

    .EditCustomFields-summary {
      transition-duration: 250ms;
      width: 100%;
      border-radius: 0.25rem;
      padding: 1rem !important;
      background-color: rgba($blue, 0.05) !important;
      cursor: default !important;

      &.expanded {
        background-color: white !important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top: 1px solid $blue;
        border-left: 1px solid $blue;
        border-right: 1px solid $blue;
      }

      .MuiAccordionSummary-contentGutters {
        margin: 0 !important;
      }
    }

    .EditCustomFields-details {
      transition-duration: 250ms;
      background-color: rgba($blue, 0.05) !important;
      padding-top: 0;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      &.expanded {
        background-color: white !important;
        border-left: 1px solid $blue;
        border-right: 1px solid $blue;
        border-bottom: 1px solid $blue;

        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }

  .MuiAutocomplete-listbox {
    .li {
      font-family: $body-font;
    }
  }

  .MuiOutlinedInput-root {
    padding-top: 0.25rem;
    padding-left: 0.25rem;
  }

  #input-type-placeholder {
    color: #9594a5;
  }

  #ElementLibrary-EditCustomFields--addOption {
    cursor: pointer;
    color: $blue;

    .MuiTypography-root {
      color: $blue;
    }

    &:hover {
      color: $dark-blue;
      .MuiTypography-root {
        color: $dark-blue;
      }
    }
  }
}

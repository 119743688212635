@import '_variables';

@mixin mobile-styles {
  @media (max-width: $mobile-break) {
    @content;
  }
}

@mixin h5-styles {
  font-family: $system-stack;
  font-weight: 700;
  font-size: 1rem;
  color: $black;
  line-height: 1;
}

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light-gray;
    border-radius: 1.25rem;
    border: 0.375rem solid transparent;
    background-clip: content-box;
    min-height: 2.5rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $medium-gray;
  }

  // Firefox Scrollbar Styles
  scrollbar-width: thin;
  scrollbar-color: $light-gray transparent;
}

@import '_variables';

.jodit_theme_gms {
  &.jodit-container {
    border: none;
  }
  .jodit-toolbar__box {
    border: none;
  }
  .jodit-toolbar-editor-collection {
    background: white;
  }
  .jodit-toolbar-editor-collection_mode_horizontal:after {
    display: none;
  }
  .jodit-wysiwyg {
    border: 1px solid $light-gray;
    border-radius: 8px;
  }
  .jodit-editor__resize svg {
    bottom: 6px;
    right: 6px;
  }
  .jodit-toolbar-button {
    .jodit-icon {
      height: 20px;
      width: 20px;
    }
  }
  .jodit-jodit__error-box-for-messages {
    display: none;
  }
  .jodit-progress-bar {
    display: none;
  }
}

// DEV-4310 Hide crop button instead of upgrading Jodit to
// a more functional and up-to-date version
.jodit-button[data-ref='editImage'] {
  display: none;
}

.jodit-popper {
  z-index: 6;
  .MuiPaper-root {
    max-height: 500px;
    overflow-y: auto;
    margin-top: 2rem;

    .MuiMenuItem-root {
      font-family: Roobert;
      z-index: 6;
    }
  }
}

.jodit-valid-tag {
  background-color: rgb(238, 240, 252);
}

.jodit-invalid-tag {
  color: $red;
  background-color: rgb(251, 224, 226);
}

.indented-menu-item {
  padding-left: 2rem;
}

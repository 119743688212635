@import '_variables';

.giving-form-preview-box {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.ab-test {
    border: 2px solid $green;
  }

  &.ab-test-draft {
    border: 2px solid $dark-blue;
  }

  // Needed to avoid 4px of misalignment between bordered and non-bordered cards
  &:not(.ab) .giving-form-preview-box-card .channel-preview-header {
    padding-top: 0.25rem;
  }

  .archived-grayed-out {
    background-color: rgba(2, 0, 38, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
  }

  .channel-card-chip {
    padding: 0.375rem 0.875rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 3;

    &.draft {
      background-color: $almost-black;
    }

    &.unpub-changes {
      background-color: $orange;
    }

    &.ab-test {
      background-color: $green;
    }

    &.ab-test-draft {
      background-color: $dark-blue;
    }

    &.sms-form {
      left: 1rem;
      right: unset;
      background-color: $purple;
    }

    &.archived {
      background-color: $lighter-gray;
      border: 1px solid $gray;
      color: $gray;
      z-index: 4;
    }
  }

  &-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .channel-preview-header {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &.ab-test-purple-gradient {
      background: $purple-gradient;
    }
    &.ab-test-green-gradient {
      background: $green-gradient;
    }
    &.ab-test-yellow-gradient {
      background: $yellow-gradient;
    }
    .preview-image {
      width: 11rem;
      height: 5.125rem;
      object-fit: cover;
      object-position: 0 0;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.08));
      z-index: 2;

      &-missing {
        display: none;
      }
    }
  }

  &.campaign-home {
    .channel-preview-header {
      color: $blue;
      background-color: white;
      padding: 0;

      .background-ellipse {
        position: absolute;
        right: -7.125rem;
        top: -7.125rem;

        height: 14.25rem;
        width: 14.25rem;
        border-radius: 7.125rem;

        background-color: rgba($blue, 0.08);

        .background-ellipse-center {
          position: absolute;
          right: 3.625rem;
          top: 3.625rem;
          width: 7rem;
          height: 7rem;
          border-radius: 3.5rem;

          background-color: white;
        }
      }

      &-draft {
        .preview-image {
          -webkit-filter: grayscale(1);
          filter: grayscale(1);
        }

        .background-ellipse {
          background-color: rgba($medium-gray, 0.08);
        }
      }

      &.legacy-header {
        background: rgba($blue, 0.08);
        justify-content: space-between;
        align-items: center;

        .header-label {
          padding-left: 1.5rem;
          color: $blue;
        }
      }
    }
  }

  .channel-preview-content {
    z-index: 1;
    background-color: white;
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;

    &-name {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .form-details {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
    }

    .form-detail {
      display: flex;
      justify-content: space-between;

      &-line-item {
        align-self: center;
      }

      .ab-variant-metric {
        display: inline-block;
        min-width: 3.5rem;
        text-align: end;

        &.variant-increased {
          color: $green;
        }

        &.variant-decreased {
          color: $red;
        }

        &.variant-unchanged {
          color: $dark-gray;
        }

        .variant-direction {
          position: relative;

          .variant-direction-icon {
            height: 1rem;
            width: 1rem;
            position: absolute;
            right: 0;
            top: -1px;
          }
        }
      }
    }

    .choose-variant-button {
      margin: 1rem 0;
    }

    .channel-icon-draft {
      background-color: $medium-gray;
    }
  }

  // make the giving form card appear "disabled" on
  // the Select Giving Form to A/B Test screen
  // if the giving form is already in an active test
  &.ab-test-select-form.ab-test .giving-form-preview-box-card {
    opacity: 60%;
  }
}

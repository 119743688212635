#ElementLibrary-EditHeader {
  h3 {
    margin-bottom: 1rem;
  }
  #ElementLibrary-EditHeader--title {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #dadce8; // TODO: Pull values from palette, this is "Light Grey"
  }
}

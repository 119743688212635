@import '_variables';

.dashboard-badge {
  align-items: center;
  background: linear-gradient($light-pink, $light-pink) padding-box,
    $purple-gradient border-box;
  border: 0.75px solid transparent;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin-right: 0.875rem;
  padding: 0 0.5rem;

  &-loading.MuiSkeleton-root {
    height: 1.84rem;
    margin-right: 0.875rem;
    transform: none;
    width: 14rem;
  }

  &-text-wrapper {
    align-items: center;
    display: flex;

    svg {
      fill: $purple;
      margin: 0.25rem 0;
      stroke-width: 0;
    }
  }

  &-text.MuiTypography-root {
    color: $purple;
    margin-left: 0.25rem;
  }

  &-link {
    margin-left: 2rem;
    text-decoration: none;

    h6 {
      color: $blue;
    }
  }
}

@import '_variables';

.metrics-container {
  position: sticky;
  top: 16.75rem;
  max-width: 35%;
  width: 100%;
  margin-left: 6rem;
  margin-top: 2.5rem;
}

.channel-select {
  margin-bottom: 2.5rem;
}

.campaign-container {
  padding-top: 10rem;
  display: flex;
  align-items: flex-start;
}

.campaign-details-container {
  width: 100%;
  margin-top: 2.5rem;
}

.no-channels {
  background: rgba(2, 0, 38, 0.3);
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 2rem;
  min-height: 26rem;

  & h1 {
    color: #ffffff;
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  & h5 {
    color: #ffffff;
    margin-bottom: 2rem;
  }
}

.doughnut-chart-container {
  width: 14.25rem;
  height: 14.25rem;
  margin-bottom: 1rem;
}

.chart-legend {
  margin-bottom: 1rem;

  .MuiTypography-root {
    padding: 0.25rem 0.5rem 0.325rem 0.5rem;
    width: fit-content;
    border-radius: 0.25rem;
    color: white;
    background-color: $dark-gray;
    opacity: 100%;
    cursor: pointer;

    &.legend-disabled:not(:hover) {
      background-color: $dark-gray;
      opacity: 40%;
    }
  }

  .chart-legend-all {
    background-color: $black;
  }

  .chart-legend-giving-forms {
    background-color: $blue;
  }

  .chart-legend-events {
    background-color: #aa23bf;
  }

  .chart-legend-peer-to-peer {
    background-color: #ea9633;
  }

  .chart-legend-text-to-give {
    background-color: #3fab8c;
  }

  .chart-legend-other {
    background-color: #ddba1f;
  }
}

.MuiAccordion-root {
  &.metrics-accordion {
    background-color: rgba(35, 86, 246, 0);
    box-shadow: none;
    max-width: 40rem;

    .MuiAccordionSummary-root {
      padding: 0;
      border-bottom: 1px solid $dark-gray;

      &.Mui-expanded {
        min-height: unset;
      }

      .MuiAccordionSummary-content,
      .MuiAccordionSummary-content.Mui-expanded {
        margin: 1rem 0;
      }

      &.hidden-chevron {
        .caret {
          opacity: 0%;
        }
      }
    }

    .MuiAccordionDetails-root {
      padding: 0;

      .row {
        padding: 0.5rem 0 0;
      }
    }

    &::before {
      background-color: unset;
    }

    .caret {
      margin-left: 2.5rem;
      min-width: 21px;
    }
  }
}

// breakpoints
$large-break: 1200px;
$medium-break: 900px;
$mobile-break: 600px;

$body-font: Roobert;
$display-font: Roboto;

// fonts
$display-stack: Helvetica, 'Helvetica Neue', Arial, sans-serif;
$system-stack: Helvetica, sans-serif, system, -apple-system, '.SFNSText-Regular',
  'San Francisco', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif;

// palette
$blue: #2356f6;
$black: #020026;
$almost-black: #2d2d2d;
$dark-blue: #17479e;
$very-light-blue: #e9eefe;
$purple: #aa23bf;
$light-purple: #ebcaf0;
$green: #3fab8c;
$gray: #656677;
$orange: #ea9633;
$yellow: #ddba1f;
$light-yellow: #fcf3cb;
$pink: #ec67c7;
$red: #ea333e;
$dark-gray: #656677;
$medium-gray: #9a99a8;
$light-gray: #dadce8;
$lighter-gray: #eef0fc;
$navy: #173058;
$light-pink: #e6e2f9;
$card-gray: #ccc;

$purple-gradient: linear-gradient(90deg, #c255d3 0%, #7d5ae1 104.46%);
$green-gradient: linear-gradient(90deg, #57bfa1 0%, #2356f6 104.46%);
$yellow-gradient: linear-gradient(90deg, #e6c728 27.75%, #f58e2e 103.38%);

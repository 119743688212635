@import '_variables';

.element-library-edit-goal-meter {
  .disabled {
    opacity: 0.3;
    position: relative;

    .disabled-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
  }

  .enable-goal-meter-switch {
    margin-bottom: 1.5rem;
    margin-left: -0.8125rem;

    .MuiFormControlLabel-label {
      font-family: $body-font;
      font-style: normal;
      font-weight: 900;
      font-size: 1.125rem;
    }

    .MuiSwitch-root {
      height: auto;
      padding: 0 0.85rem;
      width: 4.4rem;
    }

    .MuiSwitch-switchBase {
      padding: 0.1rem 0.95rem 0 0.95rem;

      .MuiSwitch-thumb {
        width: 1.375rem;
        height: 1.375rem;
      }

      &.Mui-checked {
        color: #ffffff;
        transform: translateX(18px);

        + .MuiSwitch-track {
          opacity: 1;
          background-color: $blue;
        }
      }

      &:hover {
        background-color: transparent;
      }
    }

    .MuiSwitch-track {
      opacity: 1;
      background-color: $light-gray;
      height: 1.6rem;
      border-radius: 2.5rem;
    }
  }

  .goal-meter-editor-section-container {
    margin-bottom: 2rem;

    .goal-meter-editor-section-title {
      margin-bottom: 1rem;
    }

    .goal-source-bold {
      font-weight: 700;
    }

    .goal-date-time-container {
      margin: 0.5rem 0 0 1.75rem;
      width: 100%;

      h5 {
        margin-bottom: 0.5rem;
      }
    }

    .goal-meter-display-options-container {
      padding: 0.5rem 0 0 0.25rem;

      .MuiFormControlLabel-label {
        font-family: $display-font;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
      }
    }
  }
}

@import '_variables';

.t2g-preview-box {
  .channel-preview-header {
    background-color: rgba($green, 0.05);

    .header-label {
      color: $green;
    }

    .header-icon {
      color: $green;
    }
  }

  .channel-preview-content {
    button {
      background-color: $green;
    }
  }
}

@import '_variables.scss';

.org-selector {
  .MuiAvatar-root.org-selector-avatar {
    height: 2rem;
    width: 2rem;
    font-size: 1rem;

    font-weight: 900;
    background-color: $navy;

    &.active {
      border: 0.125rem solid $blue;
    }
  }
}

.org-selector-dropdown {
  .MuiPopover-paper {
    font-family: $body-font;
    width: 25rem;
    padding: 1.5rem;

    box-shadow: 0px 2px 8px rgba(89, 102, 132, 0.18);
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid $lighter-gray;

    h6 {
      margin: 0;
      padding: 0;
      font-size: 0.75rem;
      color: $gray;
      margin-bottom: 0.75rem;
    }

    .MuiTextField-root {
      margin-bottom: 0.75rem;
      width: 100%;
    }

    .MuiList-root {
      max-height: 25rem;
      overflow-y: auto;

      font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    .MuiMenuItem-root {
      overflow-y: hidden;
      justify-content: space-between;
      height: 2.5rem;
      padding: 0.375rem 0.75rem;

      .org-list-item-title {
        width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      .org-list-item-selected {
        position: relative;
        width: 2rem;
        height: 100%;
      }

      .icon-badge {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 1.5rem;
        height: 1.5rem;
        fill: $blue;
      }

      .icon-check {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 1rem;
        height: 1rem;
        stroke: #ffffff;
        stroke-width: 0.125rem;
      }

      &:hover,
      &.focused {
        background-color: $lighter-gray;
        border-radius: 0.5rem;
      }

      &.selected {
        color: $blue;
        font-weight: 900;
      }
    }
  }
}

@import '_variables';

.date-range-selector {
  &.MuiToggleButtonGroup-root {
    background-color: $light-gray;
    border-radius: 0.5rem;

    & .MuiToggleButtonGroup-grouped:not(:first-of-type) {
      margin-left: 0;

      &.Mui-selected {
        border-radius: 0.5rem;
      }
    }

    & .MuiToggleButtonGroup-grouped:not(:last-of-type) {
      &.Mui-selected {
        border-radius: 0.5rem;
      }
    }
  }

  & .MuiToggleButton-root {
    border-radius: 22px;
    background-color: $light-gray;
    color: $black;
    font-weight: bold;
    font-family: 'Roboto';
    border: 0;

    &:hover {
      border-radius: 0.5rem;
    }

    &.Mui-selected {
      background-color: $blue;
      color: #ffffff;
      border-radius: 0.5rem;

      &:hover {
        background-color: $blue;
        cursor: default;
      }
    }
  }
}

.range-selector {
  .MuiTypography-caption {
    &.date-range-label {
      margin-top: 0.8rem;
    }
  }
}

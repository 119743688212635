@import '_variables';

.hosted-page-container {
  width: 100%;
  min-width: 380px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.875rem;
  margin-top: 12rem;
  // Want to use all the vertical space, minus the very top header (4.25rem) and the feature header (12rem)
  $hosted-page-editor-height: calc(100vh - 12rem - 4.25rem);
  .hosted-page-editor {
    display: grid;
    height: $hosted-page-editor-height;
    column-gap: 3.75rem;
    row-gap: 1rem;

    grid-template-areas:
      'menus library'
      'viewer library'
      'zoom library';
    $element-library-component-width: 27.1875rem; // The width needed for the element library
    grid-template-columns: 1fr $element-library-component-width;
    grid-template-rows: 4.5rem 1fr 3rem;

    align-items: start;

    .menu-selection-stack {
      grid-area: menus;
      display: flex;
      padding-top: 1.5rem;
      button {
        margin-right: 1rem;
      }
      .GivingFormEditor-EditsSavedTime {
        margin-left: auto;
      }
    }

    &.ab-test-preview {
      grid-template-rows: 3.5rem 0.5rem 1fr 3rem;
      grid-template-areas:
        'menus library'
        'label library'
        'viewer library'
        'zoom library';
    }

    .ab-test-iframe-label-container {
      grid-area: label;
      width: 100%;
      display: flex;
      justify-content: center;

      .ab-test-iframe-label {
        width: 4.6875rem;
        height: 1.5rem;
        background-color: $orange;
        border-radius: 1rem 1rem 0 0;
        display: flex;
        justify-content: center;
        align-items: center;

        h6 {
          color: #ffffff;
        }
      }
    }

    iframe {
      grid-area: viewer;
      height: 100%;
      border-radius: 0.5rem;

      &.ab-test-preview {
        border: 6px solid $orange;
        border-radius: 0.75rem;
      }
    }

    .zoom-controls {
      grid-area: zoom;
      display: flex;
      justify-content: center;
      column-gap: 2.5rem;
      button {
        width: 2rem;
        height: 2rem;
        background-color: white;
        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
        &:hover {
          border-color: $dark-blue;
          svg {
            stroke: $dark-blue;
          }
        }
        &.selected {
          border-color: $blue;
          svg {
            stroke: $blue;
          }
        }
      }
      .rotate-icon {
        transform: rotate(90deg);
      }
    }

    .active-menu-container {
      grid-area: library;
      // Next two properties exist for the element library in other layouts, but are not needed with the grid layout:
      margin-left: 0px; // This is handled by grid row-gap
      top: 0; // Top placement is handled by grid layout

      $active-menu-bottom-gap: 1.5rem; // This is the space between the bottom of the page and the Element Library when it is scrollable
      $element-library-header-size: 3.875rem; // The "Element Libary" header size
      $element-library-content-padding: 2.5rem; // Space above the Element Library component and the feature header
      padding-bottom: $active-menu-bottom-gap;
      max-height: calc(
        #{$hosted-page-editor-height} - #{$active-menu-bottom-gap}
      );
      .ElementLibraryMenu-content--container {
        max-height: calc(
          #{$hosted-page-editor-height} - #{$active-menu-bottom-gap} - #{$element-library-header-size} -
            #{$element-library-content-padding}
        );
        overflow: auto;
      }
    }
  }
}

.blocker-wrapper {
  position: relative;

  .blocker-indicator {
    padding-top: 10%;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1000;

    img {
      width: 300px;
    }
  }
}

@import '_variables';

.ab-testing-container {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
}

.explanation-container {
  flex-basis: 50%;

  .MuiTypography-h1 {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
}

.buttons-container {
  display: flex;
  gap: 1rem;
}

.divider.MuiDivider-root {
  margin: 2.5rem 0;
}

.bullets {
  margin: 3rem 0;
  padding-inline-start: 0;
}

.bullet {
  display: flex;
  width: 100%;
  gap: 1rem;

  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
}

.bullet-point {
  width: 2rem;
  height: 2rem;
  fill: $blue;
}

.award-icon-container {
  width: 3.375rem;
  height: 3.375rem;
  background-color: #e9eefe;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.award-icon.MuiSvgIcon-root {
  width: 2.5rem;
  height: 2.5rem;
  stroke-width: 1;
}

.lower-container {
  display: flex;
  width: 100%;
  gap: 1.5rem;
}

.lower-text-container {
  flex-basis: 90%;

  .MuiTypography-h1 {
    line-height: 1;
  }

  .MuiTypography-h5 {
    margin-top: 0.5rem;
  }
}

.ab-testing-image {
  flex: 0 1 462px;
  aspect-ratio: 1 / 1;
}

@import '_variables';

@mixin grid-item-textarea() {
  .MuiTextField-root {
    width: 100%;
    margin-top: 1rem;

    .MuiOutlinedInput-input {
      line-height: 1.75rem;
    }
  }
}

@mixin grid-item-textfield($col-start, $row-start) {
  grid-column: $col-start / span 2;
  grid-row: $row-start / span 1;
  .MuiOutlinedInput-root {
    height: 100%;
  }
}

.test-review-step {
  margin-top: 10rem;
  padding: 3.625rem 4.5rem;

  .test-review-header {
    display: flex;
    align-items: center;

    h2 {
      flex: 1;
    }
  }

  .test-review-preview {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    width: 100%;
    .preview-column {
      height: fit-content;
      flex: 50%;
      max-width: 50%;
      position: sticky;

      h2 {
        margin-bottom: 1rem;
      }

      .preview-image {
        width: 100%;
      }
    }
  }

  .test-review-details {
    background-color: #ffffff;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    border-radius: 0.5rem;

    &:not(.edit-mode) {
      position: relative;
      padding-bottom: 0.125rem;

      // overlay gradient for scrolling
      &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-image: linear-gradient(transparent, #fff);
        height: 20%;
      }
    }

    &.edit-mode {
      // edit-mode specific rules for the details section
      .details-layout {
        border: 1px solid $blue;
        border-radius: 0.25rem;
        padding: 1.625rem;
        max-height: none;
        overflow: auto;
      }
    }

    // global/nested rules for details section
    // these do not care about edit-mode or not
    .details-layout {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      max-height: 11.5rem;
      overflow-y: auto;

      .MuiTypography-body.no-value {
        font-style: italic;
      }

      .edit-description,
      .view-description {
        border-right: 1px solid $light-gray;
        padding-right: 1.75rem;
        @include grid-item-textarea();
      }

      .view-description,
      .view-assumption {
        .MuiTypography-h3,
        p.MuiTypography-body {
          display: inline;
          margin-right: 0.375rem;
        }
      }

      .edit-assumption,
      .view-assumption {
        border-right: 1px solid $light-gray;
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        @include grid-item-textarea();
      }

      .edit-notifications,
      .view-notifications {
        padding-left: 1.75rem;

        .ab-test-notification-container {
          @include grid-item-textfield(2, 4);
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 1rem;
          gap: 1rem;
          justify-content: space-between;

          h5 {
            text-align: center;
            margin: 0.5rem 0;

            &.hide-or-label {
              visibility: hidden;
            }
          }
        }
      }

      // Separate rule because these are very vague
      .view-notifications {
        margin-bottom: 0.5rem;
        h3 {
          margin-bottom: 0.8rem;
        }
        .MuiTypography-caption,
        .MuiTypography-h7 {
          display: inline-block;
          margin-right: 0.8rem;
        }

        // because notifyAfter is structured differently than
        // description and assumption, we have to structure
        // the "no value" state differently
        &.no-value {
          h3 {
            margin-right: 0.375rem;
            display: inline-block;
          }

          .MuiTypography-body {
            display: inline-block;
            font-style: italic;
          }
        }
      }

      .details-edit-actions {
        grid-column: 1 / span 3;

        .MuiButton-root {
          margin-top: 1.625rem;
          margin-right: 1rem;
        }
      }
    }
  }
}

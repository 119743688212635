@import '_variables';

.hosted-page-slug-modal-dialog.MuiDialog-paper {
  height: 31.5rem;
}
.hosted-page-slug-modal-title {
  &.MuiTypography-root.MuiDialogTitle-root {
    background-color: #fff;
    height: 4rem;
  }
  button {
    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
    svg {
      stroke: #808093;
      &:hover {
        background-color: #fff;
        stroke: #808093;
      }
    }
  }
}
.hosted-page-slug-modal-content {
  &.MuiDialogContent-root {
    padding: 0 5rem;
  }

  .hosted-page-slug-modal-content-title {
    margin-bottom: 4rem;
  }
  .hosted-page-slug-modal-content-text {
    padding-left: 1rem;
    color: $black;
  }

  .hosted-page-slug-modal-slug-input-container {
    display: flex;
    align-items: center;
    padding-bottom: 7.5rem;
    padding-left: 1rem;

    .hosted-page-slug-modal-base-slug {
      &.MuiTypography-root {
        color: black;
        width: fit-content;
      }
    }
    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
      padding: 0.5rem;
      position: relative;

      .MuiFormHelperText-root {
        color: $red;
        position: absolute;
        top: 3.5rem;
      }

      .MuiOutlinedInput-root {
        input {
          color: black;
        }
      }
    }
  }

  .MuiDialogActions-root {
    background-color: transparent;
    justify-content: center;
    padding-bottom: 3rem;

    .MuiButton-root {
      width: 220px;
    }
  }
  .MuiTypography-root.MuiDialogContentText-root {
    margin-bottom: 1.5rem;
  }
}

@import '_variables';

.strategy-pitch {
  display: flex;
  gap: 5rem;

  &-left {
    flex: 1;
  }

  &-right {
    flex: 0 1 462px;
  }

  &-img {
    height: auto;
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  &-title.MuiTypography-root {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &-features {
    padding-inline-start: 0;
    margin-top: 3.5rem;
  }

  &-feature {
    display: flex;
    gap: 1.5rem;

    &:not(:last-of-type) {
      margin-bottom: 3.5rem;
    }
  }

  &-feature-img {
    width: 4.5rem;
    height: 4.5rem;
  }

  &-feature-title.MuiTypography-root {
    margin-bottom: 0.5rem;
  }

  &-upgrade-btn.MuiButton-root {
    margin-top: 4.5rem;
    width: 25rem;
  }
}

@import '_variables';
.ty-body-message {
  .ty-body-message-title {
    margin-top: 0.678rem;
    margin-bottom: 1.5rem;
  }
  .ty-body-message-error {
    padding-top: 1rem;
    line-height: 1rem;
    color: $red;
  }
}

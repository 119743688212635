@import '_variables';

.active-campaigns-item {
  margin-right: 0.625rem;

  &-container {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    display: flex;
  }

  &-title {
    &.MuiTypography-root {
      max-width: 11.25rem;
      color: $blue;
      line-height: 1.3125rem;
      margin: 0;
    }
  }

  &-link {
    text-decoration: none;
  }

  &-range-label {
    &.MuiTypography-root {
      color: $dark-gray;
      margin: 0;
    }
  }

  &-name-container {
    width: 35%;
    overflow: hidden;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-donation-container {
    width: 50%;
    min-width: 10rem;
    padding-left: 1rem;
    padding-right: 1.5rem;

    h6 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-goal {
    &.MuiTypography-root {
      margin-bottom: 0.5rem;
      color: $dark-gray;
    }
  }

  &-amount-raised {
    color: $green;
  }

  &-progress-bar {
    &.MuiLinearProgress-root {
      height: 0.4375rem;
    }
  }

  &-divider {
    &.MuiDivider-root {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &-channels {
    width: 15%;

    .tooltip-trigger {
      width: 3.5rem;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $lighter-gray;
      border-radius: 0.375rem;
      padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    }
  }
}

.channels-tooltip-popper {
  .MuiTooltip-tooltip {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border-radius: 1rem;
    border: none;

    .channels-tooltip {
      pointer-events: none;
      min-width: 10rem;
      padding: 1rem 0.875rem;
      background: white;
      border-radius: 1rem;
      box-shadow: 0px 0.5rem 0.5rem rgba(50, 50, 71, 0.08),
        0px 0.5rem 1rem rgba(50, 50, 71, 0.06);

      .channels-tooltip-title {
        padding-bottom: 0.5rem;
      }

      .channels-tooltip-row {
        display: flex;

        h6 {
          font-weight: 500;
        }

        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }

        svg {
          height: 0.5rem;
          width: 0.5rem;
          margin: 0.125rem;
          margin-right: 0.3125rem;
        }

        .giving-forms {
          color: $blue;
          fill: $blue;
        }
        .events {
          color: $purple;
          fill: $purple;
        }
        .peer-to-peer {
          color: $orange;
          fill: $orange;
        }
        .text-to-give {
          color: $green;
          fill: $green;
        }
      }

      &:after {
        content: '';
        display: block;
        height: 1.5rem;
        width: 1.5rem;
        margin-left: 0;
        margin-top: -0.85rem;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 7.125rem;
        border-width: 0 0.25rem 0.25rem 0;
        transform: translateX(-50%) rotate(-45deg) skew(-10deg, -10deg);
        border-left: 0;
        border-bottom-left-radius: 0.5rem;
        border-bottom: 0;
      }
    }
  }
}

@import '_variables';
@import '../../LineGraphTooltip/LineGraphTooltip.scss';

.abtest-bar-graph-tooltip {
  padding: 0.75rem 1rem;
  background: white;
  position: absolute;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08),
    0px 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 0.5rem;
  overflow: visible;
  z-index: 10;
  overflow: visible;
  &:after {
    @include tooltip-properties;
    box-shadow: -5px 6px 5px 0px rgba(50, 50, 71, 0.08);
  }
  h2 {
    color: $blue;
  }
}

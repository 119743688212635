.channels-container {
  width: 100%;
  margin-top: 2rem;

  .channels-grid > * {
    box-sizing: content-box;
    flex: 1 0 48%;
    justify-content: flex-start;
    max-width: 49%;
    margin-top: 1.5rem;

    &.margin-on-even:nth-child(even) {
      margin-right: 0;
    }

    &:not(.margin-on-even) {
      &:first-of-type {
        flex: 1 0 100%;
        max-width: 100%;

        .overview-hover button {
          width: 30%;
        }
      }

      &:nth-child(odd) {
        margin-right: 0;
      }
    }
  }
}

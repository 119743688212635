@import '_variables';

.copy-embed-dialog {
  .MuiDialogTitle-root {
    background-color: inherit;
    button {
      background-color: white;
      color: $gray;
      border: 0;
      &:hover {
        background-color: $lighter-gray;
      }
    }
  }
  &__title {
    align-self: center;
    padding-bottom: 2rem;
  }
  .MuiDialogContent-root {
    padding-top: 0.5rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .MuiDialogActions-root {
    background-color: white;
    padding-top: 0;
    padding-bottom: 3.5rem;
    padding-left: 6rem;
    padding-right: 6rem;
    justify-content: center;
    gap: 1rem;
    .copy-embed-dialog__copy-button-box {
      display: inline-flex;
      max-width: 13rem;
      width: 100%;
      button {
        width: 100%;
      }
    }
    .copy-embed-dialog__cancel-button {
      max-width: 13rem;
      width: 100%;
    }
  }

  &__embed-code {
    position: relative;
    cursor: pointer;
    border: solid 1px $light-gray;
    padding: 0.25rem 2.5rem 0.25rem 1rem;
    border-radius: 0.5rem;
  }

  &__copy-icon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    stroke: $blue;
  }
}

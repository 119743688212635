@import '_variables';

.edit-custom-impact {
  // Must override MUI accordion styles
  margin-bottom: 1.5rem !important;
  box-shadow: none !important;

  &:last-of-type {
    margin-bottom: 0 !important;
  }

  div {
    width: 100%;
  }

  &::before {
    content: none !important;
  }

  .edit-custom-impact-summary {
    transition-duration: 250ms;
    width: 100%;
    border-radius: 0.25rem;
    padding: 1rem !important;
    background-color: rgba($blue, 0.05) !important;
    cursor: default !important;

    &.expanded {
      background-color: white !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid $blue;
      border-left: 1px solid $blue;
      border-right: 1px solid $blue;
    }

    .MuiAccordionSummary-contentGutters {
      margin: 0 !important;
      flex-direction: column;
    }

    .MuiTypography-h3 {
      margin-right: 0.5rem;
    }
  }

  .edit-custom-impact-details {
    transition-duration: 250ms;
    background-color: rgba($blue, 0.05) !important;

    padding-left: 1rem;
    padding-right: 1rem;

    .equation-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      .equation-amount,
      .equation-value {
        flex: 3.5;
      }

      .equation-equals {
        flex: 1;
        text-align: center;
      }

      .MuiTypography-h8 {
        color: $black;
      }
    }

    .MuiTypography-h5,
    .MuiButton-root {
      margin-top: 1.5rem;
    }

    &.expanded {
      background-color: white !important;
      border-left: 1px solid $blue;
      border-right: 1px solid $blue;
      border-bottom: 1px solid $blue;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      padding-top: 0;
    }
  }

  .add-impact {
    cursor: pointer;
    color: $blue;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    width: auto;

    .MuiTypography-root {
      color: $blue;
    }

    &:hover {
      color: $dark-blue;
      .MuiTypography-root {
        color: $dark-blue;
      }
    }
  }

  .custom-impact-preview-container {
    margin-top: 1rem;
    min-height: 3.375rem;
    width: 100%;
    box-shadow: 0px 4px 16px rgba(89, 102, 132, 0.12);
    border-radius: 4px;
    align-items: center;
    display: flex;
    overflow: hidden;
    position: relative;
    text-align: center;

    .custom-impact-background {
      height: 100%;
      opacity: 0.1;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    .custom-impact-content {
      padding: 0.5rem 1.375rem;
      z-index: 2;

      h5 {
        line-height: 1.25;
        font-size: 0.875rem;
        text-overflow: ellipsis;
        overflow: scroll;
      }
    }
  }
}

@import '_variables';

.note-container-hover-overlay {
  display: none;
}

.note-container:hover .note-container-hover-overlay {
  display: block;
  position: absolute;
  border-radius: 4px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: rgba($black, 0.5);
}

#note-container-edit {
  margin-bottom: 1rem;
  .MuiOutlinedInput-root {
    border-radius: 4px;
  }
  .note-container-edit-input {
    width: 100%;
    textarea {
      line-height: 28px;
    }
  }
  .note-container-save {
    margin-top: 0.5rem;
    width: 100%;
  }
}

.note-container-delete-modal {
  .MuiTypography-h2 {
    color: white;
  }

  .MuiDialogContent-root {
    padding-left: 4.75rem;
    padding-right: 4.75rem;
    padding-top: 2.5rem;
    padding-bottom: 5.5rem;
    max-width: 40rem;
  }

  .MuiDialogActions-root {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
}

.note-container {
  #ab-note-name-loading-skeleton {
    width: 4rem;
    height: 1rem;
  }
}

@import '_variables';

.campaign-preview-card {
  background: none;
  border: none;
  text-align: left;
  padding: 1.5rem;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  min-height: 10rem;
  min-width: 48%;
  cursor: pointer;

  &.campaign-preview-card-archived {
    background: $card-gray;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:nth-child(even) {
    margin-right: 0;
  }

  .date-range {
    color: $dark-gray;
    margin-top: 0.3rem;
  }

  .MuiTypography-root {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
  }

  .MuiChip-root {
    background-color: $almost-black;
    margin-left: 1.5rem;
    cursor: pointer;

    &.campaign-card-chip-archived {
      background-color: $lighter-gray;
      border: 1px solid $gray;
      color: $gray;
      padding: 0 0.5rem;
    }
  }

  &-progress-bar {
    margin: 1.5rem 0 1rem;

    .ProgressBar .MuiLinearProgress-root {
      height: 0.5rem;
    }
    .campaign-progress-bar-archived {
      background-color: $light-gray;

      .MuiLinearProgress-bar {
        background-color: $gray;
      }
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    min-height: 2rem;
  }

  &-footer-left {
    display: flex;
    align-items: center;

    .campaign-amount-raised-archived {
      color: $gray;
    }
  }

  &-footer-right {
    display: flex;
    align-items: center;
  }

  &-goal-amount.MuiTypography-root {
    color: $dark-gray;
  }

  &-alarm-icon {
    width: 28px;
    height: 28px;
  }

  &-days-left.MuiTypography-root {
    font-weight: 600;
    margin-left: 0.2rem;
  }
}

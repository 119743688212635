@import '_variables';

.ab-step-icon-completed,
.ab-step-icon-active,
.ab-step-icon-inactive {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    font-size: 1rem;
    line-height: unset;
  }
}
.ab-stepper {
  .ab-step-label {
    font-size: 12px;
    color: $gray;
  }
  .MuiStep-root {
    .MuiStepConnector-root {
      top: 0.85rem;
      .MuiStepConnector-line {
        border-top-style: dotted;
        border-top-width: 4px;
      }
    }
  }
}

.ab-step-icon-completed {
  background: $green;
  .check-icon {
    stroke: white;
  }
}

.ab-step-icon-active {
  background: $blue;
  .MuiTypography-root {
    color: white;
  }
}

.ab-step-icon-inactive {
  background: $light-gray;
  .MuiTypography-root {
    color: $gray;
  }
}

@import '_variables';
@import '_mixins';

.user-edit {
  &-header {
    align-items: center;
    background-color: white;
    border-bottom: solid 1px $light-gray;
    display: flex;
    flex-direction: column;
    height: 12rem;
    padding: 1.5rem 2.5rem;
    width: 100%;
  }

  &-breadcrumbs {
    width: 100%;
  }

  h1,
  h2 {
    margin-top: 1.5rem;
  }

  &-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding-bottom: 3rem;

    &-row {
      align-items: stretch;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      width: 45rem;
    }

    &-label {
      align-items: stretch;
      display: flex;
      justify-content: flex-end;
      padding-right: 1.5rem;
      width: 45%;

      &.center-label {
        align-items: center;
      }

      &.top-label {
        padding-top: 0.5rem;
      }
    }

    &-field {
      width: 55%;
    }

    .key-display {
      padding: 0.5rem 0rem;
    }

    &-submit-button-container {
      margin-top: 2rem;
      width: 100%;

      display: flex;
      column-gap: 1rem;
    }
  }
}

@import '_variables';
@import '_mixins';

.ComboCheckBox {
  &__label {
    transform: translate(0.875rem, 1.075rem) scale(1) !important;
    h5 {
      @include h5-styles;
      font-weight: normal;
      color: $gray;
    }

    &--selected {
      h5 {
        color: $black;
      }
    }

    &.Mui-disabled {
      h5 {
        color: $light-gray;
      }
    }
  }

  &.Mui-focused {
    .MuiInputLabel-root {
      display: none;
    }
  }

  &__text-field {
    .MuiInputBase-root {
      display: flex;
      align-items: center;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 100% !important;
    }

    .MuiInputBase-input {
      padding-top: 0 !important;
      padding-left: 8px !important;
      padding-bottom: 0 !important;
      height: 3rem;
    }

    & .MuiAutocomplete-endAdornment {
      top: 0;
      right: 0 !important;
    }
  }

  &__separator {
    margin: -4px 0.75rem;
    border: none;
    border-top: solid 1px $light-gray;
  }

  .MuiSvgIcon-root {
    stroke: $dark-gray;
    stroke-width: 2;
  }
}

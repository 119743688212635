@import '_variables';

.element-library-edit-thank-you-gift {
  h5 {
    margin-bottom: 1rem;
    line-height: 1.5;

    span {
      font-weight: normal;
      color: #656677;
    }
  }

  .disabled {
    opacity: 0.3;
    position: relative;

    .disabled-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
  }

  .text-field,
  .checkbox-field {
    margin-top: 1.1875rem;
    margin-bottom: 1.25rem;
  }

  .text-field {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .threshold-label {
    display: block;
    min-width: 8rem;
  }

  .checkbox-field label {
    font-family: 'Roobert';
  }

  .images-field {
    p {
      margin: 0 0 0.75rem;
    }
  }

  .image-upload-container {
    margin-left: 0;
    margin-bottom: 1.25rem;

    h5 {
      margin-bottom: 0;
    }

    .upload-image-btn {
      font-size: 1rem;
    }
  }

  .tribute-images {
    margin: 0 -5px;

    .image-wrapper {
      position: relative;
      display: inline-block;
      width: 50%;
      padding: 0 5px 5px;

      img {
        width: 100%;
      }

      .delete {
        display: none;
      }
    }

    .image-wrapper:hover .delete {
      display: block;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-color: #eef0fc;
      border: solid 1px #2356f6;
      padding: 8px;
      width: 42px;
      height: 42px;
    }
  }

  .gift-add-btn {
    cursor: pointer;
    color: $blue;

    .MuiTypography-root {
      color: $blue;
    }

    &:hover {
      color: $dark-blue;
      .MuiTypography-root {
        color: $dark-blue;
      }
    }
  }

  .edit-gift-field {
    // Must override MUI accordion styles
    margin-bottom: 1.5rem !important;
    box-shadow: none !important;

    h5 {
      margin-bottom: 0;
    }

    div {
      width: 100%;
    }

    &-fieldName {
      margin-left: 0.5rem;
    }

    &-actionButtons {
      width: auto;
    }

    &::before {
      content: none !important;
    }

    padding: 1.5rem;
    border: 1px solid $blue;
    border-radius: 0.25rem;

    &-donation-option {
      .MuiFormControlLabel-label {
        p {
          font-size: 13px;
        }
      }
    }
  }
}

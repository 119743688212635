@import '_variables';

.EmailEditor-MenuSelectionStack {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.EmailEditorMenu-container {
  box-shadow: 0rem 0.25rem 2.125rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  .EmailEditorMenu-Header {
    position: relative; // Create stacking context for absolute button placement
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background-color: $blue;
    padding-top: 1rem;
    padding-bottom: 1rem;
    &__title {
      color: white;
    }
    &__subtitle {
      margin-top: 0.875rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      color: white;
      text-align: center;
    }
    &__back-button {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      // Per design, want a 40x40 clickable area
      width: 2.5rem;
      height: 2.5rem;
      svg: {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .EmailEditorMenu-content--container {
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    background-color: white;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.EmailEditor-Wrapper {
  min-width: 23.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10rem;
}

.EmailEditor-iFrameContainer {
  width: 100%;
  margin-top: 1.625rem;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1.875rem;
  // Frame height is provided by the iframe via a postMessage with the EventHub and overwrites this initial value
  & iframe {
    height: calc(100vh - 24rem);
  }

  .EmailEditor-iFrame {
    margin-bottom: 2rem;
  }
}

@import '_variables';

.channel-select-dialog {
  .MuiDialog-paper {
    min-width: 50%;
  }
  .MuiTypography-h5 {
    padding-bottom: 1.25rem;

    .selected-campaign-name {
      color: $blue;
    }
  }

  .MuiDialogContent-root {
    text-align: center;
  }

  .DialogContentTitle {
    margin-bottom: 2.2rem;
  }

  .channel-options-container {
    width: 58rem;
    max-width: 100%;
  }

  .channel-options-button-wrapper {
    .channel-button {
      height: 12rem;
      max-width: 12rem;
      flex: 1 0 30%;
      margin: 0.6875rem 1.25rem;
      background: linear-gradient(
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        linear-gradient(140.53deg, #5cba9f 14.85%, rgba(87, 191, 161, 0) 48.82%);
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
      border-radius: 100%;
      cursor: pointer;

      .channel-icon {
        width: 4rem;
        height: 4rem;
        margin-bottom: 0.9375rem;

        &.users {
          stroke: $orange;
        }

        &.desktop {
          stroke: $pink;
        }

        &.messages {
          stroke: $green;
        }

        &.speakerphone {
          stroke: $purple;
        }

        &.comet {
          stroke: $yellow;
          transform: rotate(-90deg);
        }
      }

      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }

  div.MuiDialogActions-root {
    background: #ffffff;
    justify-content: center;
    padding: 2.5rem;
  }
}

@import '_variables';

.overview-body-container {
  display: flex;
  margin-top: 10rem;
  padding-top: 3rem;

  .MuiTabs-root {
    margin-left: 1rem;
  }

  .MuiTabs-flexContainer {
    gap: 1.5rem;
  }
  .abtest-overview-charts {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    height: 21rem;

    .abtest-overview-donations {
      width: 50%;
      height: 100%;
    }

    .abtest-overview-chart-card {
      padding: 1rem;
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
      height: 100%;
      overflow: unset;
      border-radius: 0.5rem;
      > h3 {
        padding-bottom: 1.25rem;
        padding-left: 1rem;
        margin-left: -1rem;
        margin-right: -1rem;
        border-bottom: 1px solid $light-gray;
        margin-bottom: 1.25rem;
      }
    }

    .abtest-overview-transactions {
      width: 50%;
      margin-left: 1.5rem;
      height: 100%;
    }
  }
}

.right-container {
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
}

.ab-test-notes-tab {
  position: relative;
  height: 100%;
}

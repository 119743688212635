@import '_variables';

.reference-code-dialog .MuiDialog-container .MuiPaper-root {
  width: 40.5rem;
  height: 32rem;
}

.reference-code-dialog-title span {
  color: white;
}

p.reference-code-dialog-label {
  margin-top: 2.25rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.reference-code-autocomplete {
  width: 22.25rem;
}

.reference-code-input .MuiOutlinedInput-root input.MuiInputBase-input {
  padding: 0 0 0 0.25rem;
}

.reference-code-select-placeholder {
  color: $medium-gray;
}

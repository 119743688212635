@import '_variables';

div.hosted-page-select-layout-modal-container {
  max-width: 70rem;

  .MuiDialogContent-root {
    background-image: url('../../../assets/images/ellipse.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top 1.5rem center;

    .MuiDivider-root {
      margin: 1rem auto;
    }
  }
  p {
    // Need 29px between bottom of <p> text and top of options per spec
    // p body text is 16px with 28px line-height -> (28-16)/2 = 6px accounted for
    // The <Radio> element has a 9px gap accounted for in its style
    // 28px - 6px - 9px = 14px (0.875rem)
    padding-bottom: 0.875rem;
  }

  .metadata-container {
    display: flex;

    .page-favicon,
    .page-title {
      .MuiTypography-h5 {
        margin-bottom: 1rem;
      }
    }

    .page-title {
      flex: 1;
    }

    .page-favicon {
      width: 200px;
      margin-right: 1.5rem;

      .upload-favicon-btn {
        margin-top: 1rem;
      }

      .favicon-image-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 2rem;
        border: 1px solid #cccccc;
        border-radius: 0.25rem;
        width: 100%;

        &:hover .delete {
          display: block;
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          background-color: #eef0fc;
          border: solid 1px #2356f6;
          padding: 8px;
          width: 42px;
          height: 42px;
        }

        .delete {
          display: none;
        }
      }
    }
  }

  .layout-options-container {
    .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      column-gap: 4.75rem;
      row-gap: 4.5rem;
      .layout-option-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        cursor: pointer;
        .layout-image {
          position: relative;
          img {
            box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            border-radius: 10px;
          }
        }
        &:not(.selected):hover {
          h3 {
            color: $dark-blue;
          }
          .MuiRadio-root > span:first-of-type {
            border-color: $dark-blue;
          }
          .layout-image:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($dark-blue, 0.2);

            border-radius: 10px;
          }
        }
        &.selected {
          img {
            border: solid 2px $blue;
            margin: -2px;
          }
          h3 {
            color: $blue;
          }
        }
      }
    }
  }
}
.hosted-page-select-layout-modal-title span {
  color: white;
}

div.hosted-page-select-layout-modal-actions-container {
  display: flex;
  z-index: 1;
  background-color: #f8f8fa;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  button {
    width: 10.9375rem;
  }
}

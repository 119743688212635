@import '_variables';
@import '_mixins';

.active-campaigns-header-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5625rem;
}

.active-campaigns-card {
  @include custom-scrollbar;

  overflow-y: scroll;
  height: 14.75rem;
  padding-bottom: 1.75rem;

  &-skeleton-container {
    .MuiSkeleton-root {
      height: 1.875rem;
    }
  }

  &-empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-empty-text {
    &.MuiTypography-root {
      color: $dark-gray;
      opacity: 0.5;
    }
  }

  &-empty-divider {
    margin-top: 2.6875rem;
    margin-bottom: 3.125rem;
    border: none;
    background-color: $light-yellow;
    width: 14.5625rem;
    height: 0.375rem;
  }
}

@import '_mixins';

.receipt-preview-modal {
  .MuiPaper-root {
    background-color: transparent;
    // box-shadow removed here and re-applied only to MuiDialogContent-root
    // this ensures the exit button appears distinct from the content next to it
    box-shadow: none;
    overflow: visible;
    position: relative;
  }

  .exit-preview-button {
    margin-left: 1rem;
    position: absolute;
    right: -10rem;
    width: max-content;
  }

  .MuiDialogContent-root {
    border-radius: 0.5rem;
    // box-shadow copied from MUI default styles for MuiPaper-root
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    margin: 0;
    padding: 0;
    width: 45rem;
    height: 47.438rem;

    // sometimes the email content is contained within an html body, so we give
    // the html body the height as well so it stretches to the appropriate
    // height desired
    > html body {
      @include custom-scrollbar;
      height: 47.438rem;
    }
  }
}

@import '_variables';

.abtest-results {
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-title-and-days {
    display: flex;
    align-items: center;
  }

  &-activity-icon {
    color: $dark-blue;
    margin-left: 1.5rem;
    margin-right: 0.5rem;
  }

  &-skeleton.MuiSkeleton-root {
    transform: none;
  }

  &-days-active.MuiTypography-root {
    font-weight: bold;
  }
}

@import '_variables';

.dashboard-jumbo-container {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  margin-right: 1.5rem;

  .include-chapters-switch {
    max-width: 14rem;
    margin-bottom: 1rem;

    .MuiFormControlLabel-label {
      font-family: 'Roobert';
      font-style: normal;
      font-weight: 900;
      font-size: 1.125rem;
    }

    .MuiSwitch-root {
      height: auto;
      padding: 0.85rem;
      width: 3.94rem;
    }

    .MuiSwitch-switchBase {
      padding: 0.95rem;

      .MuiSwitch-thumb {
        width: 1.375rem;
        height: 1.375rem;
      }

      &.Mui-checked {
        color: #ffffff;
        transform: translateX(11px);

        + .MuiSwitch-track {
          opacity: 1;
          background-color: $blue;
        }
      }
    }

    .MuiSwitch-track {
      opacity: 1;
      background-color: $light-gray;
      height: 1.6rem;
      border-radius: 2.5rem;
    }
  }

  .top-stats {
    margin-bottom: 1.2rem;

    .range-selector {
      width: 13rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .jumbo-graph-empty-state {
    background-color: rgba(2, 0, 38, 0.3);
    height: 100%;
    border-radius: 1rem;
    padding: 2rem;

    &-heading {
      padding: 0;
      background: none;
      margin-bottom: 1rem;
    }

    &-subheading {
      color: white;
      margin-bottom: 2rem;
    }
  }

  .users-icon-wrapper {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    background-color: $dark-blue;
    padding: 0.1875rem;

    svg {
      stroke: white;
    }
  }

  .jumbo-graph {
    flex: 1;
    display: flex;
    gap: 2.5rem;
    max-height: 18.75rem;

    &-donors-stats {
      width: 17rem;

      .donor-stats-card {
        border-radius: 0.5rem;
        display: flex;
        justify-content: space-between;
        padding: 0.625rem 1.5rem;
        height: 4rem;
        margin-top: 1rem;

        .MuiTypography-root {
          color: white;
          width: max-content;
        }

        .MuiTypography-h2 {
          margin-top: 0.5rem;
        }

        &-empty {
          opacity: 0.6;
          background-color: $light-gray;

          .MuiTypography-root {
            color: $dark-gray;
          }
        }

        &-total {
          // $blue with 60% alpha
          background-color: #2356f699;
        }
        &-recurring {
          // $purple with 60% alpha
          background-color: #aa23bf99;
        }
        &-first-time {
          // $green with 65% alpha
          background-color: #3fab8ca6;
        }
      }
    }

    &-total-giving-graph {
      position: relative;
      flex: 1;
      height: 100%;

      canvas {
        width: 100% !important;
      }
    }

    &-loader {
      position: absolute;
      margin: -16px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      backdrop-filter: blur(0);
      transition: backdrop-filter 500ms;
      z-index: -1;

      &-is-loading {
        z-index: 1;
        backdrop-filter: blur(5px);
      }
    }
  }

  .jumbo-graph-empty-state {
    background-color: rgba(2, 0, 38, 0.3);
    height: 100%;
    border-radius: 1rem;
    padding: 2rem;

    &-heading {
      padding: 0;
      background: none;
      margin-bottom: 1rem;
    }

    &-subheading {
      color: white;
      margin-bottom: 2rem;
    }
  }

  .chart-container {
    max-height: 20rem;
  }
}

@import '_variables';
@import '_mixins';

.designation-list {
  &-wrapper {
    display: flex;
    align-items: baseline;
  }

  .blocker-wrapper {
    width: 100%;
  }

  .list-header-search-bar {
    margin-bottom: 1.5rem;
    margin-right: 2rem;
    flex: 1;
  }
}

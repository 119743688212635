.MultiselectCheckbox {
  .MuiOutlinedInput-root {
    & > .MuiSelect-select {
      height: 1.75rem; // Height is usually auto, but the <Text> child is 5px larger which causes a visual shift unless set
    }

    .MuiSelect-icon {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  li.MultiselectCheckbox--select.MuiMenuItem-root {
    padding-left: 0.375rem; // Usually 16px, but checkbox component adds 10px padding to left
    &.Mui-selected:not(:hover) {
      background-color: inherit;
    }
  }
}

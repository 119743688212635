.campaigns-container {
  .content-container {
    margin: -4.5rem 2.5rem 0;

    .list {
      &-container {
        min-height: 46rem;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 3rem;
      }

      &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
      }

      &-header-title {
        margin-right: 2.5rem;

        &-archived {
          margin: 2rem 0;
        }
      }

      &-header-search-bar {
        flex: 1;
      }

      &-cards {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(10.8625rem, auto);
      }

      &-card-loader {
        transform: none;
        border-radius: 1rem;
        height: 11.675rem;
      }
    }
  }
}

@import '_variables';

.dashboard-card {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1.75rem 2.5rem;

  &.active-campaigns-card {
    position: relative;
    overflow: hidden;
    padding-bottom: 0;
    padding-right: 0.625rem;
    height: 100%;

    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-image: linear-gradient(transparent, #fff);
      height: 20%;
    }
  }
}

@import '_variables';

.preview-modal-portal {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  overflow: scroll;
  overscroll-behavior: contain;

  .preview-modal {
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1300;
    flex-direction: column;
    align-items: center;
    // Workaround, without it being scrollable the overscroll-behavior on the portal doesn't block background scrolling
    min-height: calc(100vh + 1px);

    &-header {
      align-items: center;
      background-color: rgba($orange, 0.85);
      display: flex;
      justify-content: space-between;
      min-height: 7rem;
      padding: 0 2rem;
      width: 100%;
      position: fixed;
      z-index: 10;
      overflow: scroll;
      overscroll-behavior: none;

      .preview-modal-buttons-left {
        .MuiToggleButtonGroup-root {
          background-color: white;
          border-radius: 1.375rem;

          .MuiButtonBase-root {
            border: none;
            color: $gray;
            font-family: $display-font;
            font-weight: 500;
            padding: 0.5rem 1.375rem;
          }

          .Mui-selected {
            background-color: $blue;
            color: #fff;
            font-weight: 700;

            &.left-toggle {
              border-radius: 1.375rem 1rem 1rem 1.375rem;
            }

            &.right-toggle {
              border-radius: 1rem 1.375rem 1.375rem 1rem;
            }
          }
        }
      }

      .preview-modal-buttons-right {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 19rem;
      }
    }

    &-skeleton-container {
      background-color: #fff;
      border-radius: 0.75rem;
      margin: 1.5rem;
      padding: 1.5rem 2rem;

      &.mobile-skeleton {
        border-radius: 1.5rem;
        height: 810px;
        margin: 1.5rem 3rem;
        overflow: hidden;
        border-radius: 1.5rem;
        width: 360px;
        &:after {
          content: '';
          background-image: url('../../assets/images/iPhone11Pro.svg');
          background-repeat: no-repeat;
          background-position: center;
          height: 821px;
          left: 50%;
          position: absolute;
          top: 1rem;
          transform: translateX(-50%);
          width: 410px;
          pointer-events: none;
        }
      }
      .MuiSkeleton-root {
        margin-bottom: 0.5rem;
        transform: none;
        width: 100%;

        &.xsmall {
          height: 1.5rem;
        }

        &.small {
          height: 2.375rem;
        }

        &.medium {
          height: 4.1875rem;
        }

        &.large {
          height: 7.6875rem;
        }

        &.xlarge {
          height: 12.5rem;
        }
      }
    }

    &-content {
      position: relative;
      border: 5px solid rgba($orange, 0.85);
      border-radius: 0.75rem;
      margin-top: 10rem;
      margin-bottom: 3rem;
      &:not(.mobile-preview) {
        width: calc(60vw + 1.5rem * 2 + 10px);
        min-width: calc(601px + 1.5rem * 2 + 10px);
      }

      .iframe-wrapper.mobile {
        height: 810px;
        margin: 1.5rem 3rem;
        overflow: scroll;
        border-radius: 1.5rem;
        &:after {
          content: '';
          background-image: url('../../assets/images/iPhone11Pro.svg');
          background-repeat: no-repeat;
          background-position: center;
          height: 821px;
          left: 50%;
          position: absolute;
          top: 1rem;
          transform: translateX(-50%);
          width: 410px;
          pointer-events: none;
        }
      }

      iframe:not(.iframe-mobile-preview) {
        border-radius: 1rem;
        // min-width set in pixels to prevent preview from
        // hitting mobile breakpoint (600px) in desktop view
        min-width: 601px;
        margin: 1.5rem;
        width: 60vw;
        // The scroll-margin values control how far beyond the element to scroll with the PageChange event:
        scroll-margin-top: 12rem;
        scroll-margin-bottom: 5.5rem;
      }

      .iframe-mobile-preview {
        height: 810px;
        width: 360px;
        top: 0.8rem;
      }
    }
  }

  .preview-modal-backdrop {
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
  }
}

@import '_variables';
@import '_mixins';

.text-field-with-chips {
  .MuiChip-root {
    background-color: $very-light-blue;

    .MuiChip-label {
      color: $blue;
    }
  }
  .MuiTextField-root {
    height: fit-content;

    .MuiFilledInput-root {
      border: 1px solid $light-gray;
      border-radius: 0.5rem;
      font-family: $display-font;
      max-height: fit-content;
      padding: 0.5rem 1rem;

      .MuiFilledInput-input {
        height: 100%;
        padding-left: 0;
      }
    }

    &:hover {
      border-color: $blue;
      border-radius: 0.5rem;
    }

    .Mui-focused {
      background-color: white;
      border: 1px solid $blue;
    }
  }

  .MuiInputBase-root:before,
  .MuiInputBase-root:hover:not(.Mui-disabled):before,
  .MuiInputBase-root:after,
  .MuiInputBase-root:hover:after {
    border: none;
  }
}

@import '_variables';

.channel-preview-box {
  position: relative;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  min-height: 17.2rem;
  border-radius: 8px;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  background-color: hsl(0, 0%, 100%);
  overflow: hidden;

  .channel-preview-header {
    background-color: #e5ebff66;
    height: 6.75rem;
    overflow: hidden;

    .header-label {
      opacity: 0.4;
    }

    .header-icon {
      opacity: 0.2;
      transform: rotate(-30deg);
      width: 7.5rem;
      height: 7.5rem;
      align-self: end;
    }
  }

  .channel-preview-content {
    margin-top: 0;
    border-radius: 0px 0px 8px 8px;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
    min-height: 14.25rem;

    &-name .channel-icon {
      pointer-events: none;
    }

    .form-detail {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      border-bottom: 1px solid $light-gray;

      &:first-of-type {
        padding-top: 0;
      }
      &:last-of-type {
        border-bottom: none;
      }

      &.form-detail-draft:last-of-type {
        padding-bottom: 0.25rem;
        border-bottom: 1px solid $light-gray;
      }

      .label {
        color: $dark-gray;
      }
    }
  }

  .overview-hover {
    background: rgba(2, 0, 38, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
    align-items: center;
    visibility: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;

    & button {
      width: 55%;
      margin-bottom: 1rem;

      &.test-form-button .MuiButton-startIcon svg {
        fill: $blue;
      }

      &.preview-card-copy-page-link-button .MuiButton-startIcon svg {
        fill: $blue;
      }
    }
  }

  &:hover {
    .overview-hover {
      visibility: visible;
    }
  }

  .overview-archived {
    background: transparent;
  }
}

.legacy-viewer.fluid-container {
  height: 100vh;

  iframe {
    border: none;
    min-height: 100%;
    width: 100%;
    position: relative;
  }

  .default-background-header {
    position: absolute;
    left: 0;
    right: 0;
    height: 9rem;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 0.25rem 2rem 0 #0000001a;
  }
}

.flask-blocker-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;

  .flask-blocker-indicator {
    padding-top: 10%;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);

    .flask-blocker-circle {
      position: absolute;
      background-color: white;
      width: 230px;
      height: 230px;
      border-radius: 150px;
      top: calc(50vh - 55px);
      left: calc(50vw - 115px);
      box-shadow: 0px 4px 34px rgba(black, 0.1);

      .flask-blocker-text {
        margin-top: -30px;
      }

      img {
        width: 150px;
      }
    }
  }
}

.template-publish-warning-dialog {
  .MuiPaper-root {
    max-width: 40rem;
  }

  .MuiDialogTitle-root {
    .MuiTypography-root {
      color: #ffffff;
    }
  }

  .MuiDialogContent-root {
    padding: 2.75rem 3.75rem;
  }

  .warning-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.25rem;
  }

  .body-description {
    margin: 2.75rem 0;
  }

  .MuiDialogActions-root {
    padding-left: 3.75rem;
  }
}

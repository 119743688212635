@import '_variables';

.ab-test-notification,
.ab-test-time-notification {
  position: relative;
  display: flex;
  width: 100%;

  .ab-test-notification-textfield,
  .ab-test-time-notification-textfield {
    flex: 1;
  }

  .MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  &-textfield.MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  .ab-test-notification-select {
    position: relative;
    flex: 1;
  }

  .ab-test-error {
    color: $red;
    position: absolute;
    bottom: -2rem;
    left: 0;
  }

  .unit-select-default p {
    color: $medium-gray;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin-top: 0;
    top: 3.5rem;
  }
}

@import '_variables';
@import '_mixins';

.hosted-page-css-editor {
  .LookAndFeelMenu {
    position: relative;
    height: 100%;
    overflow: scroll;

    .submit-custom-css {
      margin-top: 1.5rem;
      width: fit-content;
    }

    .cssTextareaContainer {
      .editing,
      .highlighting {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 10px;
        border: 1px solid $light-gray;
        border-radius: 0.5rem;
        background-color: white;
        width: 100%;
        overflow: auto;
        white-space: nowrap;
        margin: 0;
        @include custom-scrollbar;
      }

      .editing,
      .highlighting,
      .highlighting * {
        font-size: 16px;
        font-family: monospace;
        line-height: 22px;
        tab-size: 2;
      }

      .editing {
        z-index: 1;
        color: transparent;
        background: transparent;
        caret-color: black;
        resize: none;
      }

      .highlighting {
        z-index: 0;
      }
    }
  }
}

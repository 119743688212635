@import '_variables';

.gms-content-editable {
  border: 1px solid rgb(218, 220, 232);
  width: 18rem;
  height: 3.75rem;
  padding: 0.625rem 1rem;
  box-sizing: unset;
  border-radius: 0.5rem;
  font-family: 'Roobert', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif;
  overflow-y: scroll;
  &:hover {
    background-color: #eef0fc;
    outline: 1px solid rgb(35, 86, 246);
  }
  &:focus {
    outline: 1px solid rgb(35, 86, 246);
  }

  .gms-content-editable-tag {
    background: $lighter-gray;
    color: $dark-gray;
    padding: 2px 4px;
    border-radius: 2rem;
    display: inline-block;
    font-size: 90%;
    position: relative;
    text-align: right;
    top: 1px;
    margin: 0 0.1rem;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    span {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      height: 0.75rem;
    }
    svg {
      width: 0.75rem;
      height: 0.75rem;
      position: relative;
      margin-right: 0.25rem;
      position: relative;
      top: 1px;
    }
  }

  .gms-content-editable-after,
  .gms-content-editable-before {
    display: inline;
    outline: none;
  }
}

.content-editable-helper {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

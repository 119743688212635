@import '_variables';

.ty-account-invitation {
  .ty-account-invitation-url-field {
    width: 19rem;
    height: 2.5rem;
    margin-left: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    &#ty-account-invitation-url-field input::placeholder {
      opacity: 1 !important; // needed to override !important coming from MUI
    }
  }
  .ty-account-invitation-url-field-label {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .ty-account-invitation-message-heading {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  .ty-account-invitation-error {
    padding-top: 0.5rem;
    line-height: 1rem;
    color: $red;
  }
  .ty-account-invitation-radio-heading {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

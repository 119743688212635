@import '_variables';

.EditDesignations {
  &__select-designations-header {
    margin-top: 1.5rem !important;
  }

  &__select-designations-loader {
    transform: none !important;
    background-color: $lighter-gray !important;
  }

  &__select-default {
    .MuiTextField-root {
      label {
        h5 {
          font-weight: normal;
          font-family: $system-stack;
        }
      }
      & .MuiAutocomplete-endAdornment {
        top: 0;
        right: 0 !important;

        .MuiAutocomplete-popupIndicator {
          .MuiSvgIcon-root {
            stroke: $dark-gray;
            stroke-width: 2;
          }
        }
      }
    }
  }

  &__allow-multiple-toggle {
    margin-left: -1rem !important;
  }
}

@import '_variables';

.email-card-box {
  position: relative;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  min-height: 14rem;
  max-width: 32%;
  border-radius: 8px;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  flex: 1 0 30%;
  background-color: #ffffff;

  &:nth-child(3n) {
    margin-right: 0;
  }

  .email-preview-header {
    background-color: rgba(170, 35, 191, 0.3);
    height: 6.75rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    color: $purple;
    overflow: hidden;
    opacity: 0.3;

    .header-label {
      color: $purple;
      text-transform: capitalize;
    }

    .header-icon {
      transform: rotate(-30deg);
      width: 7.5rem;
      height: 7.5rem;
      align-self: end;
    }
  }

  .email-preview-content {
    border-radius: 0px 0px 8px 8px;
  }

  .overview-hover {
    background: rgba(2, 0, 38, 0.5);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    align-items: center;
    visibility: hidden;

    & button {
      width: 55%;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .selected-overlay {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    padding: 1.125rem 1.5rem;
  }

  // same as selected overlay, but it's non-blocking
  .default-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    padding: 1.125rem 1.5rem;
  }

  &:hover {
    .overview-hover {
      visibility: visible;
    }
  }
}

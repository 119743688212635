@import '_variables';

.ElementLibraryMenu-container {
  box-shadow: 0rem 0.25rem 2.125rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  .ElementLibraryMenu-Header {
    position: relative; // Create stacking context for absolute button placement
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background-color: $blue;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .ElementLibraryMenu-Header__title {
      color: white;
    }
    .ElementLibraryMenu-Header__subtitle {
      margin-top: 0.875rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      color: white;
      text-align: center;
    }
    .ElementLibraryMenu-Header__back-button {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      // Per design, want a 40x40 clickable area
      width: 2.5rem;
      height: 2.5rem;
      svg: {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .ElementLibraryMenu-content--container {
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    background-color: white;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@import '_variables';

.MuiChip-root.abtest-status-chip {
  &-in-progress {
    background-color: $green;
  }

  &-ended {
    background-color: $dark-blue;
  }

  &-draft {
    background-color: $black;
  }
}

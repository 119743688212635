@import '_variables';
.collapsed-bar {
  display: flex;
  height: 0.5rem;
  width: 100%;
  border-radius: 9999px;
  overflow: hidden;

  &-empty {
    .collapsed-bar-channel {
      height: 100%;
      background: $light-gray;
    }
  }

  &-channel {
    display: inline-block;
    height: 100%;

    &:first-of-type {
      border-top-left-radius: 9999px;
      border-bottom-left-radius: 9999px;
    }
    &:last-of-type {
      border-top-right-radius: 9999px;
      border-bottom-right-radius: 9999px;
    }

    &-separator {
      width: 1px;
      height: 100%;
      background: white;
    }
  }
}

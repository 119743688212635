@import '_variables';

body {
  overflow-y: scroll !important;
  padding-right: 0 !important;
}

.AppHeader-Container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding: 0;
  background-color: white;
  border-bottom: 2px solid $blue;

  .AppHeader {
    &-Logo {
      width: 85px;
      height: 24px;
      stroke-width: 0.07;
    }

    &-LogoContainer {
      margin-bottom: -0.5rem;
    }

    &-Tabs {
      overflow: visible;
    }

    &-TabsScroller {
      overflow: visible !important;
    }

    &-NavTab {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    &-Menu {
      cursor: pointer;
      display: flex;
      align-items: center;

      .settings-menu {
        color: $black;
        &-selected {
          color: $blue;
        }
      }
    }

    &-UserMenu {
      margin-left: 0.75rem;
    }

    &-DropdownText {
      color: $black;
    }

    &-MenuIcon {
      font-size: 0.75rem;
      fill: $black;
      margin-top: 0.1875rem;
      margin-left: 0.5rem;
      transform: rotate(-180deg);
      transition-property: all;
      transition-duration: 200ms;

      &Open {
        transform: none;
      }
    }

    &-LinkContainer {
      position: relative;
    }

    &-Chip.MuiChip-root {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(calc(100% + 4px), 10px);
      background-color: $green;
    }
  }
}

@import '_variables';

.element-library-edit-recurring-gift-prompt {
  h3 {
    margin-bottom: 1rem;
  }

  .disabled {
    opacity: 0.3;
    position: relative;

    .disabled-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
  }

  .enable-recurring-gift-prompt-switch {
    margin-bottom: 1.5rem;
    margin-left: -0.8125rem;

    .MuiFormControlLabel-label {
      font-family: 'Roobert';
      font-style: normal;
      font-weight: 900;
      font-size: 1.125rem;
    }

    .MuiSwitch-root {
      height: auto;
      padding: 0 0.85rem;
      width: 4.4rem;
    }

    .MuiSwitch-switchBase {
      padding: 0.1rem 0.95rem 0 0.95rem;

      .MuiSwitch-thumb {
        width: 1.375rem;
        height: 1.375rem;
      }

      &.Mui-checked {
        color: #ffffff;
        transform: translateX(18px);

        + .MuiSwitch-track {
          opacity: 1;
          background-color: $blue;
        }
      }

      &:hover {
        background-color: transparent;
      }
    }

    .MuiSwitch-track {
      opacity: 1;
      background-color: $light-gray;
      height: 1.6rem;
      border-radius: 2.5rem;
    }
  }

  .text-field {
    margin-top: 1.1875rem;
    margin-bottom: 1.25rem;
  }

  .add-recurring-equation {
    align-items: center;
    color: $blue;
    cursor: pointer;
    display: flex;
    gap: 0.25rem;
    margin-top: 2rem;

    .MuiTypography-root {
      color: $blue;
    }

    &:hover {
      color: $dark-blue;
      .MuiTypography-root {
        color: $dark-blue;
      }
    }
  }
}

.redirect-url-form-dialog {
  .MuiDialog-paper {
    min-width: 38rem;
  }

  .MuiDialogTitle-root {
    .MuiTypography-root {
      color: #ffffff;
    }
  }

  .MuiDialogContent-root {
    max-width: 32.125rem;
  }

  .MuiTextField-root {
    padding: 1.25rem 0;
    width: 100%;
  }
}

.confirm-receipt-edit-modal {
  width: 45rem;
  margin: 0 auto;

  .confirm-receipt-edit-modal-title .MuiTypography-h2 {
    color: white;
  }

  .confirm-receipt-edit-form-list {
    max-height: 25rem;
    overflow-y: auto;
  }
}

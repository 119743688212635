@import '_variables';

.edit-payment-info {
  &__title.MuiTypography-root {
    margin-bottom: 1rem;
  }

  &__field-label {
    padding: 1rem 0;
  }

  &__separator.MuiDivider-root {
    margin: 1rem 0;
  }
  .transaction-fee-checked-by-default {
    margin-top: 1rem;
  }
  .give-anonymously-textarea {
    width: 20rem;
  }

  .prefix-multi-select-dropdown {
    margin-top: 1rem;
    margin-left: -1rem;
  }
}

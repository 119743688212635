@import '_variables';

.empty-state-text-display {
  width: 100%;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .heading {
    opacity: 0.5;
  }

  .empty-state-text {
    color: $dark-gray;
  }

  .empty-state-bar {
    width: 15rem;
    margin-bottom: 0.5rem;
    border-top: 6px solid $light-gray;
  }
}

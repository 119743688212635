@import '_variables';

.event-preview-box {
  .channel-preview-header {
    opacity: 1;
    background-color: rgba($purple, 0.05);

    .header-label {
      opacity: 0.4;
      color: $purple;
    }

    .header-icon {
      opacity: 1;
      color: $light-purple;
    }
  }

  .archived-grayed-out {
    background-color: rgba(2, 0, 38, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
  }

  .channel-card-chip {
    padding: 0.375rem 0.875rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 3;

    &.archived {
      background-color: $lighter-gray;
      border: 1px solid $gray;
      color: $gray;
      z-index: 4;
    }
  }

  .channel-preview-content {
    button {
      background-color: $purple;
    }
  }
}

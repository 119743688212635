@import '_variables';

.GivingFormEditor-MenuSelectionStack {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  &.ab-test-preview {
    margin-bottom: 0;
  }
}

.GivingFormEditor-Wrapper {
  min-width: 380px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 12rem;
}

.GivingFormEditor-iFrameContainer {
  width: 100%;
  margin-top: 26px; // Temp
  margin-left: auto;
  margin-right: auto;
  padding-right: 30px;
  // Frame height is provided by the iframe via a postMessage with the EventHub and overwrites this initial value
  & iframe {
    height: calc(100vh - 378px);
  }

  .ab-test-iframe-label-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .ab-test-iframe-label {
      width: 4.6875rem;
      height: 1.5rem;
      background-color: $orange;
      border-radius: 1rem 1rem 0 0;
      display: flex;
      justify-content: center;
      align-items: center;

      h6 {
        color: #ffffff;
      }
    }
  }

  .GivingFormEditor-iFrame {
    margin-bottom: 2rem;

    &.ab-test-preview {
      border: 6px solid $orange;
      border-radius: 1rem;

      iframe {
        border-radius: 1rem;
      }
    }
  }
}

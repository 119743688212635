@import '_variables';

.element-library-edit-hosted-page-header {
  h3 {
    margin-bottom: 1rem;
  }

  .element-library-edit-hosted-page-header-title {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .disabled {
    opacity: 0.3;
    position: relative;

    .disabled-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
  }

  .enable-header-switch {
    margin-bottom: 1.5rem;
    margin-left: -0.8125rem;

    .MuiFormControlLabel-label {
      font-family: 'Roobert';
      font-style: normal;
      font-weight: 900;
      font-size: 1.125rem;
    }

    .MuiSwitch-root {
      height: auto;
      padding: 0 0.85rem;
      width: 4.4rem;
    }

    .MuiSwitch-switchBase {
      padding: 0.1rem 0.95rem 0 0.95rem;

      .MuiSwitch-thumb {
        width: 1.375rem;
        height: 1.375rem;
      }

      &.Mui-checked {
        color: #ffffff;
        transform: translateX(18px);

        + .MuiSwitch-track {
          opacity: 1;
          background-color: $blue;
        }
      }

      &:hover {
        background-color: transparent;
      }
    }

    .MuiSwitch-track {
      opacity: 1;
      background-color: $light-gray;
      height: 1.6rem;
      border-radius: 2.5rem;
    }
  }

  .WYSIWYG-container {
    margin-top: 0.75rem;
  }
}
.image-upload-container {
  height: 5rem;
  margin-top: 0.5rem;
  margin-left: 2rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem;
  border: dashed $blue;
  background: rgba($blue, 0.05);
  &.disabled-overlay {
    z-index: 99;
    opacity: 0.3;
    pointer-events: none;
  }

  .upload-image-btn {
    padding: 0.5rem 0.75rem;
    height: 2rem;
    font-size: 0.75rem;
  }
}
.remove-image-btn {
  &.MuiButton-root {
    margin-left: 2rem;
    padding: 0;
  }

  &:hover {
    background-color: transparent;
  }
}
.cropper-overlay {
  &.disabled-overlay {
    width: 100%;
    height: 100%;
    z-index: 99;
    opacity: 0.3;
    pointer-events: none;
  }
}
.selected-radio {
  color: $blue;
}
.radio-group {
  display: flex;
  margin-top: 0.5rem;
  margin-left: 2rem;
}
.radio-button {
  font-family: 'Roobert';
  font-weight: bold;
}
.cropper-container {
  height: 220px;
  margin-left: 2rem;
  border-radius: 0.25rem;
  border: dashed $blue;
}

.cropper-caption {
  &.MuiTypography-root {
    font-family: 'Roboto';
    font-size: 0.75rem;
    margin-left: 2rem;
    margin-bottom: 0.5rem;
  }
}
.or {
  font-family: 'Roobert';
}

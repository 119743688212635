@import '_variables';

.corporate-matching {
  margin-top: 0.75rem;

  .corporate-matching-prompt-label {
    margin-top: 2rem;
    margin-bottom: 1.1875rem;
  }
}

@import '_variables';

.campaign-notifications {
  margin-top: 2.5rem;

  .expand-btn {
    margin-bottom: 0.5rem;
    text-decoration: none;

    .MuiTypography-root {
      color: $purple;
    }
  }

  &-cards {
    .inline-notification {
      margin-bottom: 1rem;
    }

    &-collapsed {
      position: relative;

      .inline-notification {
        z-index: 1;
      }
    }
  }

  // styles the little secondary card behind to show a stacked effect
  &-stack-behind {
    z-index: 0;
    position: absolute;
    bottom: -1rem;
    padding: 1rem;
    background: linear-gradient($light-pink, $light-pink) padding-box,
      $purple-gradient border-box;
    border-radius: 8px;
    border: 2px solid transparent;
    width: 92%;
    left: 4%;
  }
}

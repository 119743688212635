@import '_variables';

.unpub-changes-modal {
  .MuiPaper-root {
    max-width: 43.75rem;
    height: 31rem;
  }

  &-title span {
    color: white;
  }

  .DialogContentTitle {
    display: flex;
    align-items: flex-end;

    .title-space {
      width: 1rem;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;

    // cannot use Text component in DialogContentDescription
    // because this causes MUI to render a <p> nested inside a <p>
    // which throws a console error.
    // recreating h7 theme with this class
    .editor-return-message {
      // styles specific to this modal
      font-family: $body-font;
      padding-top: 2rem;

      // h7 theme styles
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 125%;
    }
  }
}

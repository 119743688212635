@import '_variables';

.feature-header {
  display: flex;
  justify-content: space-between;

  &-hover-theme-variant {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &-with-logo {
      margin-top: 0;
    }
  }

  &-container {
    position: fixed;
    top: 4.25rem;
    width: 100%;
    height: 9rem;
    padding: 1.5rem 0 0;
    background-color: white;
    box-shadow: 0 0.25rem 2rem 0 #0000001a;
    z-index: 5; // TODO: Hack fix for elements scrolling on top of this element

    &--subtitle {
      height: 10.25rem;
    }

    &--tab {
      height: 10.2675rem;
    }

    &--subtitle-and-tab {
      height: 12rem;
    }

    &-hover-theme-variant {
      position: unset;
      height: 12rem;
      z-index: 0;
      background-color: $navy;
      margin-bottom: -2rem;

      h1 {
        color: #fff;
        font-size: 2rem;
      }
    }

    .breadcrumb-container {
      padding-right: 0.5rem;
    }

    .org-logo {
      width: 6rem;
      height: 6rem;
      background-color: #fff;
      border-radius: 100%;
      object-fit: contain;
    }

    .editable-text-container {
      margin-left: -1rem;
      .editable-text-field {
        width: 33rem;

        .MuiFormHelperText-root {
          font-weight: 700;
          color: $red;
          margin-top: 0.4375rem;
        }
      }
    }

    .title-container {
      min-height: 3rem;
      margin-top: 1rem;

      &-hover-theme-variant {
        margin-top: 0;
      }
    }

    .menu-icon > .MuiSvgIcon-root {
      cursor: pointer;
      font-size: 3rem;
      stroke: $light-gray;

      > circle {
        stroke-width: 0.5;
      }
    }

    .button-container {
      margin-top: 0.625rem;

      &-hover-theme-variant {
        display: flex;
        justify-content: stretch;
        align-items: center;
        margin-top: 0;
      }
    }
  }
}

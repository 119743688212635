@import '_variables';

@mixin tooltip-properties {
  content: '';
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0;
  margin-top: -0.83rem;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 101%;
  border-width: 0 0.25rem 0.25rem 0;
  transform: translateX(-50%) rotate(-45deg) skew(-10deg, -10deg);
  border-left: 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom: 0;
  z-index: -1;
}

.line-graph-tooltip {
  pointer-events: none;
  min-width: 10rem;
  padding: 0.875rem;
  background: white;
  transform: translateX(-50%) translateY(-127%);
  border-radius: 1rem;
  box-shadow: 0px 0.5rem 0.5rem rgba(50, 50, 71, 0.08),
    0px 0.5rem 1rem rgba(50, 50, 71, 0.06);

  &.MuiTypography-caption {
    margin-top: 0.25rem;
  }

  &:after {
    @include tooltip-properties;
  }

  &-legend {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      margin: 0.125rem 0;
    }

    &.MuiTypography-h4 {
      line-height: 1.5;
    }
  }

  &-marker {
    display: inline-flex;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
}

@import '_variables';
@import '_mixins';

.users {
  &-wrapper {
    display: flex;
    align-items: baseline;
  }

  .blocker-wrapper {
    width: 100%;
  }

  .list-header-search-bar {
    margin-bottom: 1.5rem;
    flex: 1;
  }

  .action-button {
    width: 150px;
    text-align: center;
  }
}

@import '_variables';

.ab-test-preview-card-metrics {
  display: flex;
  gap: 1rem;

  &-label-container {
    width: 99%;
  }
  &-label.MuiTypography-root {
    color: $dark-gray;
  }

  &-delta {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .MuiSvgIcon-root {
      font-size: 1.125rem;
    }

    &.increase {
      color: $green;
    }
    &.decrease {
      color: $red;
    }
  }
}

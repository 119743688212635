@import '_variables';

.bar-graph-container {
  position: relative;
  z-index: 1;

  .blocker-wrapper {
    width: 100%;
    height: 100%;
    .blocker-indicator {
      background: white;
      img {
        width: 150px;
      }
    }

    > canvas {
      z-index: 2;
      width: 100% !important; // MAR - used to overwrite some of the resizing chart JS tries to do that causes some strange sizing issues
      height: 100% !important;
    }
  }
  .bar-graph-delta {
    width: 25.8rem;
    height: 0px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba($blue, 0.05);
    z-index: -1;
    border-top: 1px dashed $blue;
    border-bottom: 1px dashed $blue;
  }

  .bar-graph-legend {
    display: flex;
    justify-content: center;
    gap: 8rem;
    margin-left: 2.5rem;
    margin-top: -1rem;
    &-a {
      display: flex;
      gap: 0.5rem;
      &-color {
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        background: $purple;
      }
    }
    &-b {
      display: flex;
      &-color {
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        background: $blue;
      }
    }

    .bar-graph-delta-text {
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: column;
      visibility: hidden;
      h6 {
        color: $blue;
        padding: 5px;
        background: white;
      }
      > svg {
        height: 0.5rem;
        stroke: $blue;
        stroke-width: 4px;
        background: white;
      }
    }
  }
}

@import '_variables';

.ab-test-results-table {
  margin-top: 1rem;

  .MuiTableContainer-root {
    filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.1));

    .MuiTable-root {
      table-layout: fixed;

      // row border reset
      .MuiTableRow-root .MuiTableCell-root {
        border-bottom: none;
      }

      .header-row {
        background-color: $blue;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        .MuiTableCell-root {
          .MuiTypography-h6 {
            color: #ffffff;
            font-weight: 900;
            line-height: 125%;
          }

          &:first-of-type {
            border-top-left-radius: 0.5rem;
          }

          &:last-of-type {
            border-top-right-radius: 0.5rem;
          }
        }
      }

      .variant-a-row {
        background-color: #ffffff;
      }

      .variant-b-row {
        background-color: #f9fafb;

        .MuiTableCell-root:first-child {
          border-bottom-left-radius: 0.5rem;
        }

        .MuiTableCell-root:last-child {
          border-bottom-right-radius: 0.5rem;
        }

        &-metric-container {
          display: flex;
        }

        .ab-variant-metric {
          display: inline-block;
          min-width: 3.5rem;
          margin-left: 1.25rem;
          text-align: end;

          &.variant-increased {
            color: $green;
          }

          &.variant-decreased {
            color: $red;
          }

          &.variant-unchanged {
            color: $dark-gray;
          }

          .variant-direction {
            position: relative;

            .variant-direction-icon {
              height: 1rem;
              width: 1rem;
              position: absolute;
              right: 0;
              top: -1px;
            }
          }
        }
      }

      .header-row,
      .variant-a-row,
      .variant-b-row {
        .form-label {
          color: $dark-gray;
        }

        .MuiTableCell-root:not(:last-child) {
          border-right: 1px solid rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}

@import '_variables';

.CampaignDetail {
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 1rem;
  padding: 1.75rem;

  &-Summary {
    display: flex;
    width: 100%;
  }

  &-Avatar {
    display: inline-block;
    width: 50px;
    margin-right: 1rem;

    .MuiAvatar-root {
      background-color: $lighter-gray;
      color: $black;
      width: 51px;
      height: 51px;
    }
  }

  // Make sure the rule has parent + parent and append
  & &-DateRange {
    flex-grow: 1;

    .MuiTypography-caption {
      margin-top: 0.625rem;
      color: $dark-gray;
    }
  }

  &-DetailKeys {
    .MuiTypography-h4 {
      line-height: 1.125;
      margin-bottom: 0.625rem;
    }
  }

  &-DetailValues {
    margin-right: 1rem;

    .MuiTypography-h3 {
      color: $green;
      margin-bottom: 0.625rem;
      text-align: right;
    }
  }

  &-AmountRaised {
    .MuiTypography-h1 {
      display: inline-block;
      margin-right: 0.5rem;
    }

    .MuiTypography-h8 {
      display: inline-block;
      color: $black;
      opacity: 0.7;
    }
  }

  &-ProgressBar {
    margin-top: 1.2rem;
    width: 100%;
  }
}

@import '_variables';

.switch-element {
  margin-bottom: 1.5rem;
  margin-left: -0.8125rem;

  .MuiFormControlLabel-label {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 900;
    font-size: 1.125rem;
  }

  .MuiSwitch-root {
    height: auto;
    padding: 0 0.85rem;
    width: 4.4rem;
  }

  .MuiSwitch-switchBase {
    padding: 0.1rem 0.95rem 0 0.95rem;

    .MuiSwitch-thumb {
      width: 1.375rem;
      height: 1.375rem;
    }

    &.Mui-checked {
      color: #ffffff;
      transform: translateX(18px);

      + .MuiSwitch-track {
        opacity: 1;
        background-color: $blue;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }

  .MuiSwitch-track {
    opacity: 1;
    background-color: $light-gray;
    height: 1.6rem;
    border-radius: 2.5rem;
  }
}

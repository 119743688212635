@import '_variables';

.new-gf-with-hp-dialog {
  .MuiPaper-root {
    width: 49.125rem;
  }

  .MuiDialogTitle-root {
    height: 4.5rem;
    .MuiTypography-root {
      color: white;
    }
  }

  .DialogContentTitle {
    margin-bottom: 0.5rem;
  }

  .MuiDialogContent-root {
    padding: 3rem 3.5rem 3.5rem;
  }

  .description {
    margin-bottom: 3.5625rem;
  }

  .input-label {
    margin-bottom: 1rem;
  }

  .input-field {
    margin-bottom: 3rem;
  }

  .MuiTextField-root {
    height: auto;
    width: 100%;
  }

  .MuiButton-text {
    padding-left: 0;
    padding-right: 0;
  }

  .form-container {
    max-width: 36.375rem;
  }

  .hosted-page-slug-input-container {
    display: flex;
    align-items: center;

    .hosted-page-base-slug {
      &.MuiTypography-root {
        color: black;
        width: fit-content;
      }
    }
    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
      padding-left: 0.5rem;
      position: relative;

      .MuiFormHelperText-root {
        color: $red;
        position: absolute;
        top: 3rem;
      }

      .MuiOutlinedInput-root {
        input {
          color: black;
        }
      }
    }
  }

  .MuiDialogActions-root {
    padding-left: 3.5rem;
  }
}

@import '_variables';

.edit-custom-content {
  // Must override MUI accordion styles
  &.MuiAccordion-root,
  &.MuiAccordion-root.Mui-expanded:last-of-type {
    margin-bottom: 1.5rem;
    box-shadow: none;
  }

  &.MuiPaper-root::before {
    content: none;
  }

  &-summary.MuiAccordionSummary-root:not(.testing) {
    transition-duration: 250ms;
    width: 100%;
    border-radius: 0.25rem;
    padding: 0.75rem 1rem 0.75rem 1.5rem;
    background-color: rgba(#2356f6, 0.05);
    cursor: default !important; // Note: Had issues where Chrome would still show pointer without !important, though class precedence favored this

    &.expanded {
      background-color: white;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid $blue;
      border-left: 1px solid $blue;
      border-right: 1px solid $blue;
    }

    .MuiAccordionSummary-contentGutters {
      margin: 0;
    }
    div {
      width: 100%;
    }
  }

  &-summary.MuiAccordionSummary-root.Mui-expanded {
    min-height: 3rem;
    padding: 0 1rem 0 1.5rem;
  }

  &-details.MuiAccordionDetails-root {
    transition-duration: 250ms;
    background-color: rgba($blue, 0.05);
    padding: 0rem 1.5rem 1.5rem;

    &.expanded {
      background-color: white;
      border-left: 1px solid $blue;
      border-right: 1px solid $blue;
      border-bottom: 1px solid $blue;

      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
  // Used to keep the general sizing of the WYSIWYG editor for when it's not being rendered in order to preserve accordian transitions
  &-WYSIWYG-container {
    min-height: 300px; // Rough height of the WYSIWYG editor
  }

  .custom-name-input {
    margin-top: 1.125rem;
  }
}

#element-library-edit-custom-content-addOption {
  margin-top: 1.5rem;
  cursor: pointer;
  color: $blue;
  &.disabled,
  &.disabled:hover {
    cursor: default;
    color: $dark-gray;
    .MuiTypography-root {
      color: $dark-gray;
    }
  }

  .MuiTypography-root {
    color: $blue;
  }

  &:hover {
    color: $dark-blue;
    .MuiTypography-root {
      color: $dark-blue;
    }
  }
}

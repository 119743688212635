@import '_variables';

/** - LC
  * A lot of these styles are copied from the ChannelPreviewCard.scss.
  * The AbTestPreviewCard component uses the same kind of styling as
  * the ChannelCard components. In the future, we should create a HOC
  * CardPreview component so we're not copy/pasting styles throughout our code. 
  */

.ab-test-preview-box {
  position: relative;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
  min-height: 17.2rem;
  border-radius: 8px;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  max-width: 49%;
  background-color: hsl(0, 0%, 100%);
  flex: 1 0 48%;
  overflow: hidden;

  &.margin-on-even:nth-child(even) {
    margin-right: 0;
  }

  &:not(.margin-on-even) {
    &:first-of-type {
      flex: 1 0 100%;
      max-width: 100%;

      .overview-hover button {
        width: 30%;
      }
    }

    &:nth-child(odd) {
      margin-right: 0;
    }
  }

  .ab-test-preview-header {
    background-image: url('../../../assets/images/ab-test-card-placeholder.png');
    background-size: cover;
    background-position: center;
    height: 9.375rem;
    position: relative;

    &.header-draft {
      filter: grayscale(1);
    }
  }

  .ab-test-preview-content {
    padding: 1.5rem;
    padding-bottom: 0.5rem;
  }

  .title-container {
    display: flex;
    align-items: center;

    .MuiButtonBase-root svg {
      fill: #fff;
    }
  }

  .ab-test-title {
    padding-bottom: 0.3125rem;
  }

  .ab-test-date-range {
    color: $dark-gray;
  }

  .channel-icon {
    margin-right: 0.75rem;
    background-color: $dark-blue;

    &.icon-draft {
      background-color: $medium-gray;
    }

    > svg {
      stroke-width: 0;
    }
  }

  .form-details-container.MuiTableContainer-root {
    margin-top: 1.875rem;

    .label {
      color: $dark-gray;
    }

    tr {
      td {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
      td:first-of-type {
        padding-left: 0;
      }

      td:last-of-type {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 0;
      }
    }

    // removes the bottom border of the last row of the last table
    table:last-of-type tr:last-of-type td {
      border-bottom: 0;
    }

    // give all cells a padding
    td {
      padding: 0.25rem;
    }

    .MuiTypography-root {
      line-height: 1.75rem;
    }
  }

  .overview-hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(2, 0, 38, 0.5);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
    align-items: center;
    visibility: hidden;

    button {
      width: 55%;
      margin-bottom: 1rem;
    }
  }

  &:hover {
    .overview-hover {
      visibility: visible;
    }
  }
}

.channel-preview-box {
  min-height: initial;
}

.main-details {
  display: flex;
  justify-content: space-between;
}

.ab-test-card-chip {
  &.MuiChip-root {
    &.test-in-progress {
      background-color: $green;
    }

    &.test-ended {
      background-color: $dark-blue;
    }

    &.threshold-met {
      background-color: $blue;
    }

    &.draft {
      background-color: $black;
    }
  }
}

@import '_variables';

.receipts-container {
  width: 100%;
}

.scratch-button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.selected-receipt-container {
  width: 100%;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid $light-gray;
  margin-bottom: 2.5rem;

  .selected-receipt {
    background: #ffffff;
    border: 3px solid $blue;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 1.5rem;
    position: relative;
    min-height: 25rem;

    .success-icon {
      position: absolute;
      right: -1.25rem;
      top: -1.25rem;

      .dodecagon {
        fill: $blue;
        width: 3.5rem;
        height: 3.5rem;
      }

      .check-icon {
        position: absolute;
        left: 20%;
        top: 20%;
        width: 2rem;
        height: 2rem;
        stroke: #ffffff;
        stroke-width: 3;
      }
    }

    .receipt-details {
      width: 50%;
      padding: 3rem 2.5rem;

      .divider {
        height: 1px;
        width: 80%;
        background: $light-gray;
        margin: 1rem 0;
      }

      .receipt-description {
        width: 75%;
        margin-bottom: 8rem;
      }

      .edit-button {
        margin-right: 1rem;
      }
    }

    .receipt-preview {
      width: 50%;
      background: linear-gradient(90deg, #c255d3 0%, #7d5ae1 104.46%);
      color: rgba(255, 255, 255, 0.4);
      overflow: hidden;

      .preview-icon {
        transform: rotate(-30deg);
        width: 20.375rem;
        height: 21.75rem;
        stroke-width: 1;
      }
    }
  }
}

@import '_variables';

.inline-notification {
  background: linear-gradient($light-pink, $light-pink) padding-box,
    $purple-gradient border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  padding: 1rem;
  display: flex;
  position: relative;
  gap: 5rem;

  &-bell-icon {
    color: $purple;
  }

  &-content {
    flex: 1;
    color: $purple;
    display: flex;
    gap: 0.5rem;
  }

  &-header.MuiTypography-root {
    color: inherit;
    line-height: 125%;
  }

  &-subheader.MuiTypography-root {
    margin: 0.5rem 0 0 0;
    color: $gray;
    line-height: 125%;
  }

  &-cta-container {
    display: flex;
    align-items: center;
  }

  &-cta.MuiButton-root {
    min-width: 9rem;
    background: $purple;
    &:hover {
      background: darken($purple, 20%);
    }
  }

  &-dismiss-button-container {
    width: 2rem;
  }

  &-dismiss-button.MuiIconButton-root {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

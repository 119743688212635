@import '_variables';

.GivingFormEditor-EditsSavedTime {
  align-items: center;
  display: flex;
  align-self: end;
  h5,
  p {
    display: inline-block;
    white-space: pre; // Keeps the space between the ':' and the date
    color: $dark-gray;
  }
  .GivingFormEditor-RevertChanges {
    height: auto;
    margin: 0 0.5rem;
    padding: 0;
    width: auto;
  }
}

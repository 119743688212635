@import '_variables';

#ElementLibrary-RootMenu {
  // Box segmenting item groups, e.g. "Commonly Used" and "More Options"
  .MuiBox-root {
    h3 {
      color: $medium-gray;
      margin-bottom: 0.5rem;
    }
    // Apply this to all but the first h3 heading on the page
    &:not(:first-of-type) h3 {
      margin-top: 1.5rem;
    }
  }
  .ElementLibrary-RootMenu--item {
    width: 100%;
    // Full padding minus the internal padding for hover styling
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    button {
      // Browser button style "reset":
      background-color: white;
      border: none;
      text-align: left;

      // Spec styling:
      width: 100%;
      // Internal padding for hover styling
      padding-top: 0.6875rem;
      padding-bottom: 0.6875rem;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: $lighter-gray;
      }
    }

    .ElementLibrary-RootMenu--item-container {
      > *:first-of-type {
        // Left-hand custom icon
        margin-right: 0.5rem;
        vertical-align: middle;
      }
      h4 {
        display: inline-block;
        line-height: 1.5rem;
        margin-right: auto;
        flex: 1;
      }
      > *:last-of-type {
        // Right-hand chevron icon
        margin-left: auto;
        margin-right: 0.5rem;
        vertical-align: middle;
      }
    }
  }
}

@import '_variables';

.new-ab-test-dialog {
  .MuiDialogTitle-root {
    height: 4.5rem;
    .MuiTypography-root {
      color: white;
    }
  }

  .DialogContentTitle {
    margin-bottom: 0.5rem;
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .description {
    margin-bottom: 1.5rem;
  }

  .MuiTextField-root {
    height: auto;
    width: 100%;
  }

  .MuiButton-text {
    padding-left: 0;
    padding-right: 0;
  }

  .left-form {
    width: 50%;
    padding: 2.215rem 4.75rem;
  }

  .MuiDialogActions-root {
    padding-left: 4.75rem;
  }
}

@import '_variables';

// targeting specific UI of modal/dialog

.publish-warning-select-dialog {
  .MuiDialogTitle-root {
    height: 4.5rem;
  }
  .MuiDialogContent-root {
    height: 20.75rem;
  }

  .MuiButton-text {
    padding-left: 0;
    padding-right: 0;
  }

  .MuiPaper-root {
    height: 31.25rem;
    max-width: 40.4375rem;
    p {
      padding: 0 2rem 2rem 0;
    }
  }
}
